/* eslint-disable max-lines */
/* eslint-env jquery*/
/// <reference path="../../../typings/browser.d.ts" />
import { clamp, round } from '@deltasierra/utilities/math';
import {
    AssetAndLayerId,
    AssignedLocation,
    AutomaticTextPlaceholders,
    Axis,
    BaseImageLayer,
    BROWSER_PIXELS_PER_INCH,
    BuilderAnimation,
    BuilderAnimationDelta,
    BuilderAnimationFrame,
    BuilderDocument,
    BuilderDocumentDimensions,
    BuilderDocumentFormat,
    BuilderDocumentInspector,
    BuilderFontCustomDto,
    BuilderPrintDocumentPageOrientation,
    BuilderTemplateFormat,
    ChannelName,
    colorSchemeHelpers,
    convertToScheme,
    EditableField,
    getBoundarySize,
    getPageDimensions,
    getPageOrientationFromDimensions,
    getRectangleContainingForLayerCorners,
    HandleType,
    IHandle,
    ImageLayer,
    ImageSubstitutionType,
    initialisePages,
    IPoint,
    IRectangle,
    isBuilderDocument,
    isImageLayer,
    ISize,
    isMapLayer,
    isMultiImageContentDocument,
    isPointWithin,
    isPrintDocument,
    isTextLayer,
    isVideoDocument,
    isVideoLayer,
    Layer,
    LayerGroup,
    LayerType,
    LocationType,
    MapLayer,
    ProtocolRelativeUrl,
    RectanglePoint,
    rotateFromCenter,
    TextCase,
    TextLayer,
    translatePoint,
    VideoLayer,
} from '@deltasierra/shared';
import { isNotNullOrUndefined, isNullOrUndefined, Untyped } from '@deltasierra/type-utilities';
import ComboKeys from 'combokeys';
import * as jsondiffpatch from 'jsondiffpatch';
import * as linq from 'linq';
import { Duration } from 'luxon';
import { MvNotifier } from '../common/mvNotifier';
import { ObjectHistory } from '../common/objectHistory';
import {
    FieldValidationResult,
    FieldValidators,
    ForceUserToProvideImageValidator,
    TextFieldValidator,
    ForceUserToProvideTextValidator,
    SelectImageValidator,
} from './builderDocumentValidation';
import { BuilderEditor } from './builderEditor';
import ChannelDataService, { ChannelDataByName } from './channelDataService';
import { BuilderCommonService } from './common/builderCommonService';
import {
    ContentBuilderRenderer,
    ContentBuilderRendererFactory,
    ExportData,
    ExportOptions,
} from './contentBuilderRenderer';
import { FileCache, ImageCache, ImageLoaderService } from './imageLoaderService';
import { FileFormatChoice } from './publish/mvContentBuilderFileFormatCtrl';
import { LoadedVideo, VideoCache, VideoLoaderService } from './videoLoaderService';

import ITimeoutService = angular.ITimeoutService;
import IPromise = angular.IPromise;
import IQService = angular.IQService;

class NotAPrintDocument extends Error {}

class NotAMultiImageContentDocument extends Error {}

class InvalidPageIndex extends Error {
    public constructor(pageIndex: number) {
        super(`Invalid page index: ${pageIndex}`);
    }
}

class InvalidMultiImageIndex extends Error {
    public constructor(multiImageIndex: number) {
        super(`Invalid multi-image index: ${multiImageIndex}`);
    }
}

class CannotMoveLayer extends Error {
    public constructor(direction: 'down' | 'up') {
        super(`Cannot move layer ${direction}`);
    }
}

function clone<T>(obj: T): T {
    return angular.copy(obj);
}

interface BuilderInteraction {
    name: 'move' | 'pan' | 'transform';
    onMouseDown(contentBuilder: ContentBuilder, x: number, y: number, event: MouseEvent): void;
    onMouseMove(contentBuilder: ContentBuilder, x: number, y: number, event: MouseEvent): void;
    onMouseUp(contentBuilder: ContentBuilder, x: number, y: number, event: MouseEvent): void;
    onMouseDoubleClick(contentBuilder: ContentBuilder, x: number, y: number, event: MouseEvent): void;
    onKeydown(contentBuilder: ContentBuilder, event: KeyboardEvent): void;
    onKeyup(contentBuilder: ContentBuilder, event: KeyboardEvent): void;
    getCursor(contentBuilder: ContentBuilder, x?: number, y?: number): string;
}

class PanInteraction implements BuilderInteraction {
    public name = 'pan' as const;

    public onMouseDown(contentBuilder: ContentBuilder, x: number, y: number) {
        $(`#${contentBuilder.canvasId}`).addClass('active'); // A bit crap, should use declarative way like ng-class
    }

    public onMouseMove(contentBuilder: ContentBuilder, x: number, y: number) {
        const uiContext = contentBuilder.uiContext;
        const mouseData = uiContext.mouse;
        const sourceCanvas = window.document.getElementById(contentBuilder.canvasId) as HTMLCanvasElement;
        const offsetX = x - (mouseData.lastX || 0);
        const offsetY = y - (mouseData.lastY || 0);
        const documentDimensions = contentBuilder.getPageDimensions();
        if (documentDimensions.width * contentBuilder.uiContext.zoom > sourceCanvas.width) {
            const horiz = uiContext.panHorizontal - (offsetX / uiContext.zoom) * (1 / documentDimensions.width) * 2;
            uiContext.panHorizontal = clamp(0, 2, horiz);
        } else {
            uiContext.panHorizontal = 1.0;
        }
        if (documentDimensions.height * contentBuilder.uiContext.zoom > sourceCanvas.height) {
            const vert = uiContext.panVertical - (offsetY / uiContext.zoom) * (1 / documentDimensions.height) * 2;
            uiContext.panVertical = clamp(0, 2, vert);
        } else {
            uiContext.panVertical = 1.0;
        }
    }

    public onMouseUp(contentBuilder: ContentBuilder, x: number, y: number) {
        $(`#${contentBuilder.canvasId}`).removeClass('active'); // A bit crap, should use declarative way like ng-class
    }

    public onMouseDoubleClick(contentBuilder: ContentBuilder, x: number, y: number) {
        if (contentBuilder.advancedMode) {
            const clickedLayer = contentBuilder.trySelectLayer(x, y);
            if (clickedLayer && !isVideoLayer(clickedLayer)) {
                contentBuilder.setMouseAction('transform');
                contentBuilder.updateMouseCursor(x, y);
            }
        }
    }

    public onKeydown(contentBuilder: ContentBuilder, event: KeyboardEvent) {
        // Do nothing
    }

    public onKeyup(contentBuilder: ContentBuilder, event: KeyboardEvent) {
        // Do nothing
    }

    public getCursor(contentBuilder: ContentBuilder, x?: number, y?: number): string {
        return 'grab';
    }
}

interface LayerDetails {
    center: IPoint;
    size: ISize;
}

class TransformInteraction implements BuilderInteraction {
    public name = 'transform' as const;

    private readonly corners = Object.freeze([
        RectanglePoint.topLeft,
        RectanglePoint.topRight,
        RectanglePoint.bottomLeft,
        RectanglePoint.bottomRight,
    ]);

    private clickMoved = false;

    private hasMouseDown = false;

    private lastMousePoint: IPoint | null = null;

    private clickedPoint: IHandle | null = null;

    private pivotPoint: IPoint | null = null;

    private selectionStart: LayerDetails | null = null;

    private spaceDown = false;

    private shouldPan = false;

    private hasPanned = false;

    private addedLayer = false;

    private panInteraction: PanInteraction = new PanInteraction();

    private lockAxis: Axis | null = null;

    private hasDuplicated = false;

    public onMouseDown(contentBuilder: ContentBuilder, x: number, y: number, event: MouseEvent) {
        this.clickedPoint = contentBuilder.getMousedOverPointForSelectedLayerGroup(x, y);
        if (!this.clickedPoint && !(this.spaceDown && this.shouldPan)) {
            this.handleLayerSelection(contentBuilder, x, y, event);
        }

        if (
            contentBuilder.selectedLayerGroup.isAny(layer => layer.visible) &&
            !contentBuilder.selectedLayerGroup.visibleLayers.some(isVideoLayer)
        ) {
            $(`#${contentBuilder.canvasId}`).addClass('active'); // A bit crap, should use declarative way like ng-class
            this.hasMouseDown = true;
            this.lastMousePoint = { x, y };
            const containingRect = contentBuilder.selectedLayerGroup.getContainingRectangle();
            if (containingRect) {
                this.selectionStart = {
                    center: {
                        x: containingRect.x,
                        y: containingRect.y,
                    },
                    size: {
                        height: containingRect.height,
                        width: containingRect.width,
                    },
                };
            }
            if (this.clickedPoint && contentBuilder.selectedLayerGroup.selectedLayer) {
                const center = contentBuilder.translatePositionToViewCanvas(
                    contentBuilder.selectedLayerGroup.selectedLayer.position,
                );
                this.pivotPoint = this.oppositePoint(
                    {
                        x: this.clickedPoint.x,
                        y: this.clickedPoint.y,
                    },
                    center,
                    contentBuilder.selectedLayerGroup.selectedLayer.rotation,
                    this.clickedPoint.positionName,
                );
            }
        }
    }

    // eslint-disable-next-line complexity, max-statements
    public onMouseMove(contentBuilder: ContentBuilder, x: number, y: number, event: MouseEvent) {
        if (contentBuilder.selectedLayerGroup.isAny()) {
            if (
                this.clickedPoint !== null &&
                this.clickedPoint !== undefined &&
                this.clickedPoint.type === HandleType.resize &&
                contentBuilder.selectedLayerGroup.selectedLayer
            ) {
                this.clickMoved = true;
                this.resize(contentBuilder, x, y, event);
                if (contentBuilder.selectedLayerGroup.selectedLayer.type === LayerType.text) {
                    contentBuilder.selectedLayerGroup.selectedLayer.width = Math.abs(
                        contentBuilder.selectedLayerGroup.selectedLayer.width,
                    );
                    contentBuilder.selectedLayerGroup.selectedLayer.height = Math.abs(
                        contentBuilder.selectedLayerGroup.selectedLayer.height,
                    );
                }
            } else if (
                this.clickedPoint !== null &&
                this.clickedPoint !== undefined &&
                this.clickedPoint.type === HandleType.rotate &&
                contentBuilder.selectedLayerGroup.selectedLayer
            ) {
                this.clickMoved = true;
                const snapInterval = event.shiftKey ? 15 : 45;
                const snapBuffer = event.shiftKey ? 7.5 : 3;
                let newAngle = this.calculateAngle(contentBuilder, x, y);
                const deviation = newAngle % snapInterval;
                if (newAngle < snapBuffer || deviation <= snapBuffer) {
                    newAngle = this.rotate(newAngle, -deviation);
                } else if (deviation >= snapInterval - snapBuffer) {
                    newAngle = this.rotate(newAngle, snapInterval - deviation);
                }
                this.tryDuplicateLayerGroup(contentBuilder, event);
                contentBuilder.selectedLayerGroup.selectedLayer.rotation = newAngle;
            } else if (!this.spaceDown && !this.shouldPan && this.lastMousePoint) {
                this.clickMoved = true;
                // Move the layer
                const offsetX = (this.lastMousePoint.x - x) * (1 / contentBuilder.uiContext.zoom);
                const offsetY = (this.lastMousePoint.y - y) * (1 / contentBuilder.uiContext.zoom);
                this.tryDuplicateLayerGroup(contentBuilder, event);
                if (event.shiftKey) {
                    if (
                        (this.lockAxis === null || this.lockAxis === undefined) &&
                        (Math.abs(offsetX) > 0 || Math.abs(offsetY) > 0)
                    ) {
                        this.lockAxis = Math.abs(offsetX) > Math.abs(offsetY) ? 'x' : 'y';
                    }
                }

                const updateX = this.lockAxis ? this.lockAxis === 'x' : true;
                const updateY = this.lockAxis ? this.lockAxis === 'y' : true;

                for (const updateLayer of contentBuilder.selectedLayerGroup.visibleLayers) {
                    if (updateX) {
                        updateLayer.position.x -= offsetX;
                        if (contentBuilder.selectedLayerGroup.isMultiple(true)) {
                            updateLayer.position.x = Math.round(updateLayer.position.x);
                        }
                    }
                    if (updateY) {
                        updateLayer.position.y -= offsetY;
                        if (contentBuilder.selectedLayerGroup.isMultiple(true)) {
                            updateLayer.position.y = Math.round(updateLayer.position.y);
                        }
                    }
                }
            } else {
                this.tryPan(contentBuilder, x, y);
            }
        } else {
            this.tryPan(contentBuilder, x, y);
        }

        this.lastMousePoint = { x, y };
    }

    public onMouseUp(contentBuilder: ContentBuilder, x: number, y: number, event: MouseEvent) {
        $(`#${contentBuilder.canvasId}`).removeClass('active'); // A bit crap, should use declarative way like ng-class
        this.hasMouseDown = false;
        this.selectionStart = null;
        this.clickedPoint = null;
        this.lockAxis = null;
        this.hasDuplicated = false;
        if (event.shiftKey && contentBuilder.selectedLayerGroup.isMultiple() && !this.clickMoved && !this.addedLayer) {
            const mousedOverLayer = contentBuilder.getMouseOverLayer(x, y);
            if (mousedOverLayer) {
                contentBuilder.deselectLayer(mousedOverLayer);
            }
        }
        if (!this.spaceDown) {
            this.shouldPan = false;
        }
        if (contentBuilder.selectedLayerGroup.selectedLayer) {
            if (isVideoLayer(contentBuilder.selectedLayerGroup.selectedLayer)) {
                return;
            }

            contentBuilder.selectedLayerGroup.selectedLayer.width = Math.abs(
                contentBuilder.selectedLayerGroup.selectedLayer.width,
            );
            contentBuilder.selectedLayerGroup.selectedLayer.height = Math.abs(
                contentBuilder.selectedLayerGroup.selectedLayer.height,
            );
        }

        contentBuilder.captureDocumentChange();
        this.clickMoved = false;
        this.addedLayer = false;
    }

    public onMouseDoubleClick(contentBuilder: ContentBuilder, x: number, y: number, event: MouseEvent) {
        contentBuilder.trySelectLayer(x, y, event.shiftKey);
    }

    public onKeydown(contentBuilder: ContentBuilder, event: KeyboardEvent) {
        switch (event.code) {
            case 'Space':
                this.spaceDown = true;
                if (!this.hasMouseDown) {
                    this.shouldPan = true;
                }
                break;

            default:
                break;
        }
    }

    public onKeyup(contentBuilder: ContentBuilder, event: KeyboardEvent) {
        switch (event.code) {
            case 'Space':
                this.spaceDown = false;
                if (!this.hasMouseDown) {
                    this.shouldPan = false;
                }
                if (this.hasPanned) {
                    this.hasPanned = false;
                    event.preventDefault();
                }
                break;

            default:
                break;
        }
    }

    // eslint-disable-next-line max-statements
    public getCursor(contentBuilder: ContentBuilder, x: number, y: number): string {
        const handle =
            x !== null && x !== undefined && y !== null && y !== undefined
                ? contentBuilder.getMousedOverPointForSelectedLayerGroup(x, y)
                : null;

        const mouseOverLayer = contentBuilder.getMouseOverLayer(x, y);

        if (handle === null || handle === undefined) {
            if (this.spaceDown) {
                return this.panInteraction.getCursor(contentBuilder, x, y);
            } else if (mouseOverLayer !== null && mouseOverLayer !== undefined) {
                return 'move';
            } else {
                return '';
            }
        }

        if (handle.type === HandleType.rotate) {
            return 'crosshair';
        }

        function matchHandleCursor(handles: RectanglePoint[][], cursors: string[], type: RectanglePoint) {
            for (let i = 0; i < handles.length; i++) {
                if (handles[i].indexOf(type) !== -1) {
                    return cursors[i];
                }
            }

            return '';
        }

        if (handle.type === HandleType.resize) {
            const rotation = contentBuilder.selectedLayerGroup.selectedLayer
                ? contentBuilder.selectedLayerGroup.selectedLayer.rotation
                : 0;
            const cursors = ['ns-resize', 'nesw-resize', 'ew-resize', 'nwse-resize'];
            const handles = [
                [RectanglePoint.top, RectanglePoint.bottom],
                [RectanglePoint.topRight, RectanglePoint.bottomLeft],
                [RectanglePoint.left, RectanglePoint.right],
                [RectanglePoint.topLeft, RectanglePoint.bottomRight],
            ];
            const rotationAllowance = 45; // Degrees
            let currentAngle = 22.5;
            if (rotation < currentAngle || rotation > this.rotate(currentAngle, -rotationAllowance)) {
                return matchHandleCursor(handles, cursors, handle.positionName);
            }

            do {
                currentAngle += rotationAllowance;
                if (handles.length > 0) {
                    handles.unshift(handles.pop()!);
                }
            } while (!(rotation > this.rotate(currentAngle, -rotationAllowance) && rotation < currentAngle));

            return matchHandleCursor(handles, cursors, handle.positionName);
        }
        return '';
    }

    private calculateAngle(contentBuilder: ContentBuilder, x: number, y: number) {
        if (!contentBuilder.selectedLayerGroup.selectedLayer) {
            return 0;
        }
        const virtualCoords = contentBuilder.pageToCanvas(x, y);
        const layerCenter = contentBuilder.translatePositionToViewCanvas(
            contentBuilder.selectedLayerGroup.selectedLayer.position,
        );
        const xDiff = virtualCoords.x - layerCenter.x;
        const yDiff = virtualCoords.y - layerCenter.y;

        const mouseRadians = Math.atan2(xDiff, yDiff);
        const mouseDegrees = mouseRadians * ((180 / Math.PI) * -1) + 180;

        return Math.floor(mouseDegrees);
    }

    private getOffset(start: IPoint, end: IPoint, zoom?: number) {
        let offsetX = end.x - start.x;
        let offsetY = end.y - start.y;
        if (zoom !== null && zoom !== undefined) {
            offsetX *= 1 / zoom;
            offsetY *= 1 / zoom;
        }

        return { x: offsetX, y: offsetY };
    }

    private correctOffsetOnRectPoint(position: RectanglePoint, offset: IPoint) {
        const correctedOffset = { x: offset.x, y: offset.y };
        const reveseXPoints = [RectanglePoint.topLeft, RectanglePoint.bottomLeft, RectanglePoint.left];
        const reveseYPoints = [RectanglePoint.topLeft, RectanglePoint.topRight, RectanglePoint.top];

        if (reveseXPoints.indexOf(position) !== -1) {
            correctedOffset.x = -offset.x;
        }
        if (reveseYPoints.indexOf(position) !== -1) {
            correctedOffset.y = -offset.y;
        }

        return correctedOffset;
    }

    private offsetCenterFromHandlePosition(position: RectanglePoint, offset: IPoint, center: IPoint) {
        const tempCenter = { x: center.x, y: center.y };
        switch (position) {
            case RectanglePoint.top:
            case RectanglePoint.bottom:
                tempCenter.y += offset.y / 2;
                break;
            case RectanglePoint.left:
            case RectanglePoint.right:
                tempCenter.x += offset.x / 2;
                break;
            default:
                // Corners
                tempCenter.x += offset.x / 2;
                tempCenter.y += offset.y / 2;
        }

        return tempCenter;
    }

    private isCorner() {
        return !!this.clickedPoint && this.corners.indexOf(this.clickedPoint.positionName) !== -1;
    }

    private rotate(rotation: number, change: number) {
        const tempRotation = rotation + change;

        if (tempRotation > 360) {
            return tempRotation - 360;
        } else if (tempRotation < 0) {
            return 360 + tempRotation;
        }

        return tempRotation;
    }

    // Offset is the change of the layer center from the center of canvas
    //              ^ -y
    //              |
    //     -x, -y   |   x, -y
    //              |
    // -------------|-------------> x
    //              |
    //     -x, y    |   x, y
    //              |

    // eslint-disable-next-line max-statements
    private resize(contentBuilder: ContentBuilder, x: number, y: number, event: MouseEvent) {
        const resizeLayer = contentBuilder.selectedLayerGroup.selectedLayer;
        if (resizeLayer && this.pivotPoint && this.clickedPoint && this.selectionStart) {
            const center = contentBuilder.translatePositionToViewCanvas(resizeLayer.position);
            const canvasClick = contentBuilder.pageToCanvas(x, y);
            let widthChange = 0;
            let heightChange = 0;
            let centerOffset = { x: 0, y: 0 };

            const normPivot = rotateFromCenter(this.pivotPoint, center, resizeLayer.rotation, true);
            const click = rotateFromCenter(canvasClick, center, resizeLayer.rotation, true);
            const tempSize = this.getOffset(normPivot, click, contentBuilder.uiContext.zoom);
            const newSize = this.correctOffsetOnRectPoint(this.clickedPoint.positionName, tempSize);

            // Maintain Aspect Ratio
            if (resizeLayer.lockAspectRatio || ((isImageLayer(resizeLayer) || event.shiftKey) && this.isCorner())) {
                const tempWidth = Math.abs(newSize.x);
                const tempHeight = Math.abs(newSize.y);
                const ratioHeight = tempWidth * (this.selectionStart.size.height / this.selectionStart.size.width);
                const ratioWidth = tempHeight * (this.selectionStart.size.width / this.selectionStart.size.height);
                const scaleOnX = tempHeight <= ratioHeight;
                const scaleOnY = tempWidth < ratioWidth;

                const xFlip = newSize.x < 0;
                const yFlip = newSize.y < 0;

                if (scaleOnX) {
                    widthChange = newSize.x - this.selectionStart.size.width;
                    heightChange = ratioHeight - this.selectionStart.size.height;
                } else {
                    widthChange = ratioWidth - this.selectionStart.size.width;
                    heightChange = newSize.y - this.selectionStart.size.height;
                }

                centerOffset = this.correctOffsetOnRectPoint(this.clickedPoint.positionName, {
                    x: widthChange - (xFlip && scaleOnY ? ratioWidth * 2 : 0),
                    y: heightChange - (yFlip && scaleOnX ? ratioHeight * 2 : 0),
                });
            } else {
                widthChange = newSize.x - this.selectionStart.size.width;
                heightChange = newSize.y - this.selectionStart.size.height;
                centerOffset = this.correctOffsetOnRectPoint(this.clickedPoint.positionName, {
                    x: widthChange,
                    y: heightChange,
                });
            }

            const correctedCenter = this.offsetCenterFromHandlePosition(
                this.clickedPoint.positionName,
                centerOffset,
                this.selectionStart.center,
            );
            let newCenter = rotateFromCenter(correctedCenter, this.selectionStart.center, resizeLayer.rotation);

            // Scale uniformly
            if (event.altKey) {
                newCenter = this.selectionStart.center;
                widthChange *= 2;
                heightChange *= 2;
            }

            resizeLayer.position.x = newCenter.x;
            resizeLayer.position.y = newCenter.y;

            if (
                RectanglePoint.top !== this.clickedPoint.positionName &&
                RectanglePoint.bottom !== this.clickedPoint.positionName
            ) {
                resizeLayer.width = this.selectionStart.size.width + widthChange;
            }
            if (
                RectanglePoint.left !== this.clickedPoint.positionName &&
                RectanglePoint.right !== this.clickedPoint.positionName
            ) {
                resizeLayer.height = this.selectionStart.size.height + heightChange;
            }
        }
    }

    private oppositePoint(point: IPoint, center: IPoint, rotation: number, rectPoint: RectanglePoint, zoom?: number) {
        const normPoint = rotateFromCenter(point, center, rotation, true);
        const offset = this.getOffset(normPoint, center, zoom);
        const opposite = { x: center.x + offset.x, y: center.y + offset.y };

        return rotateFromCenter(opposite, center, rotation);
    }

    private tryPan(contentBuilder: ContentBuilder, x: number, y: number) {
        if (this.spaceDown && this.shouldPan) {
            this.clickMoved = true;
            this.hasPanned = true;
            this.panInteraction.onMouseMove(contentBuilder, x, y);
        }
    }

    private tryDuplicateLayerGroup(contentBuilder: ContentBuilder, event: MouseEvent) {
        if (!this.hasDuplicated && event.altKey) {
            const visibleLayers = contentBuilder.selectedLayerGroup.visibleLayers;
            contentBuilder.selectedLayerGroup.removeAll();
            for (const layer of visibleLayers) {
                contentBuilder.duplicateLayer(layer, { group: true });
            }
            contentBuilder.captureDocumentChange('Duplicate Layers');
            this.hasDuplicated = true;
        }
    }

    private handleLayerSelection(contentBuilder: ContentBuilder, x: number, y: number, event: MouseEvent) {
        const mousedOverLayer = contentBuilder.getMouseOverLayer(x, y);
        if (mousedOverLayer) {
            const isSelected = contentBuilder.selectedLayerGroup.contains(mousedOverLayer);
            if (!isSelected) {
                contentBuilder.trySelectLayer(x, y, event.shiftKey);
                this.addedLayer = true;
            }
        } else {
            contentBuilder.trySelectLayer(x, y, event.shiftKey);
        }
    }
}

const interactionMap: { [key: string]: new () => BuilderInteraction } = {
    pan: PanInteraction,
    transform: TransformInteraction,
};

function interactionFactory(action: 'move' | 'pan' | 'transform'): BuilderInteraction | null {
    const interactionClass = interactionMap[action];
    if (!interactionClass) {
        return null;
    } else {
        return new interactionClass();
    }
}

const videoSpeed = (1 / 30) * 1000; // Update 30 FPS
export class BuilderVideoPlayer {
    public isPlaying = false;

    public isMuted = false;

    public duration = Duration.fromMillis(0);

    public currentTime = Duration.fromMillis(0);

    private frameUpdatePromise: IPromise<any> | undefined;

    public constructor(
        private readonly $timeout: ITimeoutService,
        private readonly contentBuilder: ContentBuilder,
        private readonly videoCache: VideoCache,
    ) {}

    public startFromBeginning(): void {
        this.processVideos((video: LoadedVideo) => {
            video.element.currentTime = 0;
            video.element.play();
            video.element.muted = this.isMuted;
        });
        if (this.frameUpdatePromise) {
            this.$timeout.cancel(this.frameUpdatePromise);
        }
        this.updateFramesAndRenderAndQueueNextUpdate();
        this.isPlaying = true;
    }

    public pause(): void {
        this.processVideos((video: LoadedVideo) => {
            video.element.pause();
        });
        if (this.frameUpdatePromise) {
            this.$timeout.cancel(this.frameUpdatePromise);
        }
        this.isPlaying = false;
    }

    public toggleMute(): void {
        this.isMuted = !this.isMuted;
    }

    public getLongestVideo(videos: LoadedVideo[]): LoadedVideo | null {
        const videosSortedByDuration = videos.sort(
            (videoA, videoB) => videoB.element.duration - videoA.element.duration,
        );
        if (videosSortedByDuration.length === 0) {
            return null;
        }
        return videosSortedByDuration[0];
    }

    public getLongestVideoDuration(): number {
        const longestVideo = this.getLongestVideo(this.getVideos());
        return longestVideo ? longestVideo.element.duration : 0;
    }

    public updateTimes(videos: LoadedVideo[]): void {
        const longestVideo = this.getLongestVideo(videos);
        if (longestVideo) {
            this.currentTime = Duration.fromMillis(longestVideo.element.currentTime * 1000);
            this.duration = Duration.fromMillis(longestVideo.element.duration * 1000);
        }
    }

    private getVideos(): LoadedVideo[] {
        const videoLayers = this.contentBuilder.getVideoLayers();
        const results = [];
        for (const layer of videoLayers) {
            if (layer.location) {
                const video = this.videoCache.get(layer.location);
                if (video) {
                    video.element.muted = layer.removeAudio || this.isMuted;
                    results.push(video);
                }
            }
        }
        return results;
    }

    private processVideos(fn: (video: LoadedVideo) => void, videosArray?: LoadedVideo[]): void {
        const videos = videosArray ?? this.getVideos();
        videos.forEach(fn);
    }

    private isAVideoStillRunning(videos: LoadedVideo[]): boolean {
        let atLeastOneVideoStillRunning = false;
        this.processVideos((video: LoadedVideo) => {
            if (!video.element.ended) {
                atLeastOneVideoStillRunning = true;
            }
        }, videos);
        return atLeastOneVideoStillRunning;
    }

    private updateFramesAndRenderAndQueueNextUpdate() {
        const videos = this.getVideos();
        const stillRunning = this.isAVideoStillRunning(videos);
        if (stillRunning) {
            this.updateTimes(videos);
            this.contentBuilder.render();
            this.queueNextUpdate();
        } else {
            this.$timeout.cancel(this.frameUpdatePromise); // Not necessary...
            this.frameUpdatePromise = undefined;
            this.isPlaying = false;
        }
    }

    private queueNextUpdate() {
        this.frameUpdatePromise = this.$timeout(() => this.updateFramesAndRenderAndQueueNextUpdate(), videoSpeed);
    }
}

export class ContentBuilderFactory {
    public static SID = 'ContentBuilderFactory';

    public static readonly $inject: string[] = [
        '$q',
        '$timeout',
        ContentBuilderRendererFactory.SID,
        BuilderEditor.SID,
        ImageLoaderService.SID,
        VideoLoaderService.SID,
        ChannelDataService.SID,
        BuilderCommonService.SID,
        MvNotifier.SID,
    ];


    public constructor(
        public $q: IQService,
        public $timeout: ITimeoutService,
        public contentBuilderRendererFactory: ContentBuilderRendererFactory,
        public builderEditor: new () => BuilderEditor,
        public imageLoaderService: ImageLoaderService,
        public videoLoaderService: VideoLoaderService,
        public channelDataService: ChannelDataService,
        public builderCommonService: BuilderCommonService,
        public mvNotifier: MvNotifier,
    ) {}

    public getInstance(
        canvasId: string,
        imageCache: ImageCache,
        fileCache: FileCache,
        videoCache: VideoCache,
        selectDocument: () => void,
    ): ContentBuilder {
        return new ContentBuilder(this, canvasId, imageCache, fileCache, videoCache, selectDocument);
    }
}

export interface ContentBuilderPageContext {
    pageIndex: number;
}

export interface ContentBuilderMultiImageContext {
    imageIndex: number;
}

export type ActionType = 'move' | 'pan' | 'transform';

export interface ContentBuilderUiContext {
    zoom: number;
    panHorizontal: number;
    panVertical: number;
    mouse: {
        left: boolean;
        lastX: number | null;
        lastY: number | null;
    };
    action: ActionType;
    interaction: BuilderInteraction | null;
    lineDashOffset: number;
    showPlaceholderOverlays: boolean;
}

export interface AnimationContext {
    frameIndex: number;
    promise: IPromise<void> | null;
    running: boolean;
    loopCount: number;
}

interface ContentBuilderHistory {
    animationFrameIndex: number;
    document: BuilderDocument;
    linkedAssetLibraryAsset: AssetAndLayerId[];
    multiImageContext: ContentBuilderMultiImageContext;
    pageContext: ContentBuilderPageContext;
    substitutionTextValues: { [id: string]: any };
}

export class ContentBuilder {
    private static MAX_ZOOM = 16;

    private static MIN_ZOOM = 0.01;

    private static validators = new FieldValidators()
        .add(new SelectImageValidator())
        .add(new ForceUserToProvideImageValidator())
        .add(new TextFieldValidator())
        .add(new ForceUserToProvideTextValidator());

    public idCount = 1;

    // CanvasId : string;
    public uiContext: ContentBuilderUiContext = {
        action: 'pan',
        interaction: null,
        lineDashOffset: 0,
        mouse: {
            lastX: null,
            lastY: null,
            left: false,
        },
        panHorizontal: 1.0,
        panVertical: 1.0,
        showPlaceholderOverlays: true,
        zoom: 1.0,
    };

    public animationContext = this.createAnimationContext();

    public pageContext: ContentBuilderPageContext = { pageIndex: 0 };

    public multiImageContext: ContentBuilderMultiImageContext = this.createMultiImageContext();

    public textSubstitutionValues: { [id: string]: any } = {};

    public renderer: ContentBuilderRenderer;

    public document = new BuilderDocument();

    public customFonts: BuilderFontCustomDto[] = [];

    public advancedMode = true;

    public keyboardShortcutsEnabled = true;

    public simpleEditor: BuilderEditor;

    public selectedLayerGroup: LayerGroup = new LayerGroup();

    public resourceLoadingCount = 0;

    public formats: BuilderTemplateFormat[] = [];

    public videoPlayer: BuilderVideoPlayer;

    // A map containing the original text layer texts where the key is the text layer IDs.
    public originalTextLayerText: Map<number, string> = new Map<number, string>();

    public documentHistory = new ObjectHistory(
        new jsondiffpatch.DiffPatcher({
            objectHash(obj: { id?: any; _id?: any }, index: number) {
                // This function is used only to when objects are not equal by ref
                return obj.id || obj._id || `$$index:${index}`;
            },
            propertyFilter(name: string, context: any) {
                return name.slice(0, 1) !== '$';
            },
        }),
        this.getHistoryObject(),
    );

    public builderShortcuts = new ComboKeys(document.documentElement);

    public inspector = new BuilderDocumentInspector();

    public highlightLayer: Layer | null = null;

    public linkedAssetLibraryAsset: AssetAndLayerId[] = [];


    public constructor(
        private injected: ContentBuilderFactory,
        public canvasId: string,
        public imageCache: ImageCache,
        public fileCache: FileCache,
        public videoCache: VideoCache,
        public selectDocument: () => void,
    ) {
        this.renderer = this.injected.contentBuilderRendererFactory.getInstance(
            canvasId,
            imageCache,
            videoCache,
            this.uiContext,
            this.textSubstitutionValues,
        );
        this.simpleEditor = new this.injected.builderEditor();
        this.videoPlayer = new BuilderVideoPlayer(injected.$timeout, this, videoCache);
        this.setupListeners();
    }

    public disableKeyboardShortcuts(): void {
        this.builderShortcuts.reset();
    }

    public enableKeyboardShortcuts(): void {
        this.bindBuilderShortcuts();
    }

    public resetDocumentHistory(): void {
        this.documentHistory.reset(this.getHistoryObject());
    }

    public captureDocumentChange(description?: string): void {
        this.documentHistory.captureChange(this.getHistoryObject(), description);
    }

    public setHighlightedLayer(layer: Layer): void {
        this.highlightLayer = layer;
    }

    public clearHighlightedLayer(): void {
        this.highlightLayer = null;
    }

    public getTranslatedExteriorPointsForLayer(layer: Layer): IHandle[] {
        const exteriorPoints = this.renderer.getExteriorPointsForLayer(layer);
        const newCenter = this.translatePositionToViewCanvas(layer.position);
        const translatedPoints = exteriorPoints.map(point => {
            const rotatedPoint = rotateFromCenter(
                { x: point.x + newCenter.x, y: point.y + newCenter.y },
                newCenter,
                layer.rotation,
            ) as IHandle;
            rotatedPoint.positionName = point.positionName;
            rotatedPoint.type = point.type;
            return rotatedPoint;
        });

        return translatedPoints;
    }

    public trySelectLayer(x: number, y: number, group = false): Layer | null {
        const clickedLayer = this.getMouseOverLayer(x, y);
        if (this.isWithinCanvas({ x, y })) {
            if (clickedLayer === null || clickedLayer === undefined) {
                this.selectDocument();
            } else if (isVideoLayer(clickedLayer) && this.uiContext.action === 'transform') {
                this.setMouseAction('pan');
            } else {
                this.selectLayer(clickedLayer, group);
            }
            return clickedLayer;
        }
        return null;
    }

    public translatePositionToViewCanvas(position: IPoint): IPoint {
        const sourceCanvas = window.document.getElementById(this.canvasId) as HTMLCanvasElement;
        const fromSize = { height: this.renderer.workCanvas.height, width: this.renderer.workCanvas.width };
        const toSize = { height: sourceCanvas.height, width: sourceCanvas.width };
        const center = translatePoint(
            position,
            fromSize,
            {
                panX: this.uiContext.panHorizontal,
                panY: this.uiContext.panVertical,
                zoom: this.uiContext.zoom,
            },
            toSize,
        );

        return center;
    }

    public getTranslatedExteriorPointsForLayerGroup(layerGroup: LayerGroup): IHandle[] {
        const exteriorPoints = this.renderer.getExteriorPointsForLayerGroup(layerGroup);
        const boundary = this.renderer.getOverlayRectForLayerGroup(layerGroup);
        if (boundary && layerGroup.selectedLayer) {
            const newCenter = this.translatePositionToViewCanvas({ x: boundary.x, y: boundary.y });
            const rotation = !layerGroup.isMultiple(true) ? layerGroup.selectedLayer.rotation : 0;
            const translatedPoints = exteriorPoints.map(point => {
                const rotatedPoint = rotateFromCenter(
                    { x: point.x + newCenter.x, y: point.y + newCenter.y },
                    newCenter,
                    rotation,
                ) as IHandle;
                rotatedPoint.positionName = point.positionName;
                rotatedPoint.type = point.type;
                return rotatedPoint;
            });

            return translatedPoints;
        } else {
            return [];
        }
    }

    public getMousedOverPointForSelectedLayerGroup(x: number, y: number): IHandle | null {
        if (!this.selectedLayerGroup.isAny(layer => layer.visible) || this.selectedLayerGroup.isMultiple()) {
            return null;
        }

        const canvasCoords = this.pageToCanvas(x, y);
        const exteriorPoints = this.getTranslatedExteriorPointsForLayerGroup(this.selectedLayerGroup);
        const handleSize = { height: 10, width: 10 };
        const foundPoint = linq.from(exteriorPoints).firstOrDefault(point => {
            const isWithin = isPointWithin(
                canvasCoords,
                { height: handleSize.height, width: handleSize.width, x: point.x, y: point.y },
                0,
            );
            return isWithin;
        }, undefined);

        return foundPoint;
    }

    public getLayerFromPoint(x: number, y: number): Layer | null {
        const foundLayer = linq
            .from(this.document.layers)
            .where(layer => layer.visible)
            .where(layer => this.isLayerOnPage(layer))
            .where(layer => this.isLayerOnMultiImageOption(layer))
            .reverse()
            .firstOrDefault(layer => {
                const center = this.translatePositionToViewCanvas(layer.position);
                const clickPosition = rotateFromCenter({ x, y }, center, layer.rotation, true);
                const boundary = getBoundarySize(
                    { height: layer.height, width: layer.width },
                    { skewX: layer.skewY, skewY: layer.skewX, zoom: this.uiContext.zoom },
                );

                const isWithin = isPointWithin(
                    clickPosition,
                    { height: boundary.height, width: boundary.width, x: center.x, y: center.y },
                    2,
                );

                return isWithin;
            });

        return foundLayer;
    }

    public async loadDocument(document: BuilderDocument, customFonts: BuilderFontCustomDto[]): Promise<void> {
        this.document = document;
        this.customFonts = customFonts;
        this.documentHistory.reset(this.getHistoryObject());
        if (isPrintDocument(document)) {
            initialisePages(document);
        }
        const promise = this.populateImageCache(document);
        this.checkEditorFields();
        this.initEditorFromDocument(document);
        this.initIdCount(document);
        if (document.animation) {
            this.setAnimationFrame(0);
        }

        document.layers.filter(isTextLayer).forEach(textLayer => {
            this.originalTextLayerText.set(textLayer.id, textLayer.text);
        });

        return promise;
    }

    public render(): void {
        if (
            this.document !== null &&
            this.document.dimensions.width !== 0 &&
            this.document.dimensions.width !== undefined &&
            this.document.dimensions.height !== 0 &&
            this.document.dimensions.height !== undefined
        ) {
            this.renderer.render({
                advancedMode: this.advancedMode,
                builderDocument: this.document,
                customFonts: this.customFonts,
                formats: this.formats,
                highlightLayers: this.highlightLayer ? [this.highlightLayer] : [],
                multiImageIndex: this.multiImageContext.imageIndex,
                pageIndex: this.pageContext.pageIndex,
                selectedLayerGroup: this.selectedLayerGroup,
            });
        }
    }

    public renderSimpleToCanvasId(canvasId: string): void {
        if (this.document !== null) {
            this.renderer.renderSimpleToCanvasId(canvasId, this.document, this.customFonts);
        }
    }

    public deleteLayer(layerToDelete: Layer): void {
        const deleteIndex = this.document.layers.indexOf(layerToDelete);
        if (deleteIndex !== -1) {
            this.document.layers.splice(deleteIndex, 1);
        }

        // DS-3905: Remove the associated linked asset library asset layer
        if (isImageLayer(layerToDelete) || (isVideoLayer(layerToDelete) && layerToDelete.location)) {
            this.linkedAssetLibraryAsset = this.linkedAssetLibraryAsset.filter(
                ({ layerId }) => layerToDelete.id !== layerId,
            );
        }

        // Even though the layer might not be in the layers array we should clean up everywhere else just incase
        this.simpleEditor.deleteFieldsForLayer(layerToDelete.id);
        this.deleteEditableFieldsForLayer(layerToDelete.id);
        this.deleteAnimationDeltasForLayer(layerToDelete.id);
        this.deletePageMapForLayer(layerToDelete.id);
        this.deleteMultiImageMapForLayer(layerToDelete.id);
        this.deselectLayer(layerToDelete);
        this.captureDocumentChange('Delete layer');
    }

    public cleanUpLayerMapping(): void {
        const layerIds = this.document.layers.map(layer => layer.id);
        if (isPrintDocument(this.document)) {
            this.document.pages.forEach(page => {
                // Remove any layer id's where the layer doesn't exist
                page.layerIds = page.layerIds.filter(id => layerIds.indexOf(id) > -1);
            });
        } else if (isMultiImageContentDocument(this.document)) {
            this.document.multiImage.forEach(image => {
                image.layerIds = image.layerIds.filter(id => layerIds.indexOf(id) > -1);
            });
        }
    }

    public addLayer(layer: Layer, toBottom?: boolean): Layer {
        const doc = this.document;

        if (toBottom) {
            doc.layers.unshift(layer);
        } else {
            doc.layers.push(layer);
        }
        this.addDeltaToFirstFrameIfRequired(layer);

        if (isPrintDocument(doc)) {
            doc.pages[this.pageContext.pageIndex].layerIds.push(layer.id);
        } else if (isMultiImageContentDocument(doc)) {
            doc.multiImage[this.multiImageContext.imageIndex].layerIds.push(layer.id);
        }

        this.captureDocumentChange('Add Layer');

        return layer;
    }

    public addRectangle(): Layer {
        return this.addLayerByType(LayerType.rect);
    }

    public addEllipse(): Layer {
        return this.addLayerByType(LayerType.ellipse);
    }

    public addText(): Layer {
        return this.addLayerByType(LayerType.text);
    }

    public addMap(): MapLayer {
        const layer = new MapLayer(this.idCount++);
        layer.title = this.generateLayerTitle();
        return this.addLayer(layer) as MapLayer;
    }

    public addVideo(): Layer {
        const layer = new VideoLayer(this.idCount++);
        const dimensions = this.getPageDimensions();
        layer.width = dimensions.width;
        layer.height = dimensions.height;
        return this.addLayer(layer, true);
    }

    public addImage(): ImageLayer {
        const layer = new ImageLayer(this.idCount++);
        layer.title = `New Layer #${this.document.layers.length + 1}`;
        return this.addLayer(layer) as ImageLayer;
    }

    public addImageFromElement(imageElement: HTMLImageElement, location: string, dpi: number): Layer {
        const layer = new ImageLayer(this.idCount++);
        layer.title = `New Layer #${this.document.layers.length + 1}`;
        this.setImageFromElement(imageElement, location, layer, dpi);
        return this.addLayer(layer);
    }

    public isLocationIsUsedByALayer(location: string): boolean {
        for (const layer of this.document.layers) {
            if ((isImageLayer(layer) || isVideoLayer(layer)) && layer.location === location) {
                return true;
            }
        }
        return false;
    }

    public setImageFromElement(
        imageElement: HTMLImageElement,
        location: string,
        layer: ImageLayer,
        dpi?: number,
    ): ImageLayer {
        this.setImageSourceFromElement(imageElement, location, layer);
        layer.width = dpi ? (imageElement.width / dpi) * BROWSER_PIXELS_PER_INCH : imageElement.width;
        layer.originalWidth = dpi ? (imageElement.width / dpi) * BROWSER_PIXELS_PER_INCH : imageElement.width;
        layer.height = dpi ? (imageElement.height / dpi) * BROWSER_PIXELS_PER_INCH : imageElement.height;
        layer.originalHeight = dpi ? (imageElement.height / dpi) * BROWSER_PIXELS_PER_INCH : imageElement.height;

        return layer;
    }

    public setImageFromElementForReplacement(
        imageElement: HTMLImageElement,
        location: string,
        layer: ImageLayer,
    ): ImageLayer {
        this.setImageSourceFromElement(imageElement, location, layer);
        if (layer.forceUserToProvideImage) {
            layer.forceUserToProvideImage = false;
        }
        return layer;
    }

    public setImageSourceFromElement(
        imageElement: HTMLImageElement,
        location: string,
        layer: BaseImageLayer,
    ): BaseImageLayer {
        const originalLocation = layer.location;
        layer.locationType = this.getLocationType(imageElement.src);
        layer.location = location;

        if (
            originalLocation &&
            originalLocation !== layer.location &&
            !this.isLocationIsUsedByALayer(originalLocation)
        ) {
            this.imageCache.remove(ProtocolRelativeUrl.from(originalLocation));
        }
        if (layer.showPlaceholderOverlay) {
            layer.showPlaceholderOverlay = false;
        }

        return layer;
    }

    public setVideoFromElement(
        videoElement: HTMLVideoElement,
        location: string,
        layer: VideoLayer,
        locationType: LocationType,
    ): VideoLayer {
        const originalLocation = layer.location;
        layer.locationType = locationType;
        layer.location = location;
        layer.originalWidth = videoElement.videoWidth;
        layer.originalHeight = videoElement.videoHeight;

        const dimensions = this.scaleToFill(
            {
                height: layer.originalHeight,
                width: layer.originalWidth,
            },
            this.getPageDimensions(),
        );
        layer.width = dimensions.width;
        layer.height = dimensions.height;

        if (
            originalLocation &&
            originalLocation !== layer.location &&
            !this.isLocationIsUsedByALayer(originalLocation)
        ) {
            this.videoCache.remove(originalLocation);
            this.fileCache.remove(originalLocation);
        }
        return layer;
    }

    public onDocumentDimensionsUpdated(): void {
        // This function fixes the scaling that doesn't get updated when you change the template's dimensions
        const videoLayer = this.document.layers.find(isVideoLayer);
        if (videoLayer) {
            const dimensions = this.scaleToFill(
                {
                    height: videoLayer.originalHeight || videoLayer.height,
                    width: videoLayer.originalWidth || videoLayer.width,
                },
                this.getPageDimensions(),
            );
            videoLayer.width = dimensions.width;
            videoLayer.height = dimensions.height;
        }
    }

    public pageToCanvas(eventX: number, eventY: number): { x: number; y: number } {
        const pageData = this.calculateCanvasPageData();
        return { x: eventX - pageData.left, y: eventY - pageData.top };
    }

    public async exportAsImage(exportOptions?: ExportOptions): Promise<ExportData> {
        const exportedData = await this.renderer.exportAsImage(this.document, this.customFonts, exportOptions);
        return exportedData;
    }

    public async exportMultiImageItemAsImage(
        multiImageIndex: number,
        exportOptions?: ExportOptions,
    ): Promise<ExportData | null> {
        if (this.document.multiImage && this.document.multiImage.length > multiImageIndex && multiImageIndex >= 0) {
            const layers = this.getLayersOnMultiImage(multiImageIndex);
            const document = new BuilderDocument();
            document.layers = layers;
            document.dimensions = this.document.dimensions;
            document.background = this.document.background;
            const exportedData = await this.renderer.exportAsImage(document, this.customFonts, exportOptions);
            return exportedData;
        }
        return null;
    }

    public async exportPrintTemplatePageAsImage(
        pageIndex: number,
        exportOptions?: ExportOptions,
    ): Promise<ExportData | null> {
        if (isPrintDocument(this.document) && pageIndex < this.document.pages.length && pageIndex >= 0) {
            const layers = this.getLayersOnPage(pageIndex);
            const document = new BuilderDocument();
            document.layers = layers;
            document.dimensions = this.document.dimensions;
            const exportedData = await this.renderer.exportAsImage(document, this.customFonts, exportOptions);
            return exportedData;
        }
        return null;
    }

    public async exportMultiImageTemplateAsImages(exportOptions?: ExportOptions): Promise<ExportData[] | null> {
        if (!this.document.multiImage) {
            return null;
        }
        const exportDataPromises = this.document.multiImage.map(async (_, index) =>
            this.exportMultiImageItemAsImage(index, exportOptions),
        );
        const exportData = await Promise.all(exportDataPromises);
        return exportData.filter(isNotNullOrUndefined);
    }

    public getVideoLayers(): VideoLayer[] {
        return this.inspector.getVideoLayers(this.document);
    }

    public exportAsCanvas(exportOptions?: FileFormatChoice): HTMLCanvasElement {
        const exportedCanvas = this.renderer.exportAsCanvas(this.document, this.customFonts, exportOptions);
        return exportedCanvas;
    }

    public validateField(layer: Layer, fieldPath: string): FieldValidationResult[] {
        const result = ContentBuilder.validators.validateField({
            advancedMode: this.advancedMode,
            document: this.document,
            fieldPath,
            layer,
            originalTextLayerText: this.originalTextLayerText,
        });
        return result;
    }

    public validateLayer(layer: Layer): FieldValidationResult[] {
        const result = ContentBuilder.validators.validateLayer({
            advancedMode: this.advancedMode,
            document: this.document,
            layer,
            originalTextLayerText: this.originalTextLayerText,
        });
        return result;
    }

    public validateDocument(): FieldValidationResult[] {
        const result = ContentBuilder.validators.validateDocument({
            advancedMode: this.advancedMode,
            document: this.document,
            originalTextLayerText: this.originalTextLayerText,
        });
        return result;
    }

    public toggleEditableField(
        layerOrDocument: BuilderDocument | Layer,
        fieldPath: string,
        controlType: string,
        label: string,
    ): void {
        if (this.hasEditableField(layerOrDocument, fieldPath, controlType)) {
            this.deleteEditableField(layerOrDocument, fieldPath, controlType);
        } else {
            this.addEditableField(layerOrDocument, fieldPath, controlType, label);
        }
    }

    public hasEditableField(layerOrDocument: BuilderDocument | Layer, fieldPath: string, control: string): boolean {
        if (isBuilderDocument(layerOrDocument)) {
            return this.simpleEditor.hasTemplateField(fieldPath, control);
        }
        return this.simpleEditor.hasField(layerOrDocument.id, fieldPath, control);
    }

    public updateDocumentFromEditor(): void {
        for (const field of this.simpleEditor.fields) {
            this.setFieldValue(field.layer as Layer, field.config.path, field.value);
        }
    }

    public updateEditorFromDocument(): void {
        for (const field of this.simpleEditor.fields) {
            field.value = this.getFieldValue(field.layer as Layer, field.config.path);
        }
    }

    public bulkSelectLayers(clickedLayer: Layer): void {
        this.selectLayer(clickedLayer, true);
        const positions = this.selectedLayerGroup.layers.map(x => this.document.layers.indexOf(x));
        if (positions.length) {
            positions.sort((pos1, pos2) => pos1 - pos2);
            const highest = positions[0];
            const lowest = positions[positions.length - 1];
            this.document.layers.forEach((layer, i) => {
                if (i >= highest && i <= lowest) {
                    this.selectedLayerGroup.add(layer);
                } else {
                    this.selectedLayerGroup.remove(layer);
                }
            });
        }
    }

    public selectLayer(layer: Layer, group = false): void {
        const doc = this.document;
        if (isPrintDocument(doc) && layer) {
            const pageIndex = linq.from(doc.pages).indexOf(page => page.layerIds.indexOf(layer.id) >= 0);

            if (pageIndex > -1) {
                this.pageContext.pageIndex = pageIndex;
            }
        } else if (isMultiImageContentDocument(doc) && layer) {
            const multiImageIndex = linq.from(doc.multiImage).indexOf(image => image.layerIds.indexOf(layer.id) > -1);
            if (multiImageIndex > -1) {
                this.multiImageContext.imageIndex = multiImageIndex;
            }
        }

        if (layer && isVideoLayer(layer) && this.uiContext.action === 'transform') {
            this.setMouseAction('pan');
        }

        // Backward compatibility with layers still using previous uppercase / lowercase values
        if (isTextLayer(layer) && !('textCase' in layer)) {
            if (layer.uppercase) {
                layer.textCase = TextCase.uppercase;
            } else if (layer.lowercase) {
                layer.textCase = TextCase.lowercase;
            }
        }

        if (group) {
            this.selectedLayerGroup.addOrRemove(layer);
        } else {
            this.selectedLayerGroup = new LayerGroup(layer);
        }
    }

    public deselectLayer(layer: Layer): void {
        if (!layer) {
            return;
        }

        this.selectedLayerGroup.remove(layer);
    }

    public deselectAllLayers(): void {
        this.selectedLayerGroup.removeAll();
    }

    public getPageIndexOfLayer(layer: Layer): number {
        if (layer === null || layer === undefined) {
            return -1;
        }

        const doc = this.document;
        if (isPrintDocument(doc)) {
            return linq.from(doc.pages).indexOf(page => page.layerIds.indexOf(layer.id) > -1);
        }

        throw new NotAPrintDocument('Cannot get page index for layer when document is not a print document');
    }

    public getLayersOnPage(pageIndex: number): Layer[] {
        const doc = this.document;

        if (pageIndex < 0) {
            throw new InvalidPageIndex(pageIndex);
        }

        if (isPrintDocument(doc)) {
            if (pageIndex >= doc.pages.length) {
                throw new InvalidPageIndex(pageIndex);
            }

            const { layerIds } = doc.pages[pageIndex];
            const layers = linq
                .from(doc.layers)
                .where(layer => layerIds.indexOf(layer.id) > -1)
                .toArray();
            return layers;
        }

        if (pageIndex > 0) {
            throw new InvalidPageIndex(pageIndex);
        }

        return [];
    }

    public getMultiImageIndexOfLayer(layer: Layer): number {
        if (isNullOrUndefined(layer)) {
            return -1;
        }
        const doc = this.document;
        if (isMultiImageContentDocument(doc)) {
            return linq.from(doc.multiImage).indexOf(image => image.layerIds.indexOf(layer.id) > -1);
        }
        throw new NotAMultiImageContentDocument(
            'Cannot get multi-image index for layer when document is not a multi-image content document',
        );
    }

    public getLayersOnMultiImage(multiImageIndex: number): Layer[] {
        const doc = this.document;
        if (multiImageIndex < 0) {
            throw new InvalidMultiImageIndex(multiImageIndex);
        }
        if (isMultiImageContentDocument(doc)) {
            if (multiImageIndex >= doc.multiImage.length) {
                throw new InvalidMultiImageIndex(multiImageIndex);
            }
            const { layerIds } = doc.multiImage[multiImageIndex];
            const layers = linq
                .from(doc.layers)
                .where(layer => layerIds.indexOf(layer.id) > -1)
                .toArray();
            return layers;
        }
        if (multiImageIndex > 0) {
            throw new InvalidPageIndex(multiImageIndex);
        }
        return [];
    }

    /**
     * For a print document, will return the index of the specified layer within just the collection of layers on the
     * same page. For all other documents will return the index of the layer within all layers.
     *
     * @param layer - Layer
     * @returns position of layer
     */
    public getPositionOfLayer(layer: Layer): number {
        const doc = this.document;
        let layers: Layer[];

        if (isPrintDocument(doc)) {
            const pageIndex = this.getPageIndexOfLayer(layer);
            layers = this.getLayersOnPage(pageIndex);
        } else if (isMultiImageContentDocument(doc)) {
            const multiImageIndex = this.getMultiImageIndexOfLayer(layer);
            layers = this.getLayersOnMultiImage(multiImageIndex);
        } else {
            layers = doc.layers;
        }

        const result = layers.indexOf(layer);

        return result;
    }

    public isLayerOnPage(layer: Layer): boolean {
        if (isPrintDocument(this.document)) {
            return this.document.pages[this.pageContext.pageIndex].layerIds.indexOf(layer.id) !== -1;
        }

        return true;
    }

    public isLayerOnMultiImageOption(layer: Layer): boolean {
        if (isMultiImageContentDocument(this.document)) {
            return this.document.multiImage[this.multiImageContext.imageIndex].layerIds.indexOf(layer.id) !== -1;
        } else {
            return true;
        }
    }

    public canMoveLayerUp(layerToMove: Layer): boolean {
        const doc = this.document;
        let layers: Layer[];

        if (isPrintDocument(doc)) {
            const pageIndex = this.getPageIndexOfLayer(layerToMove);
            if (pageIndex === -1) {
                return false;
            }

            layers = this.getLayersOnPage(pageIndex);
        } else if (isVideoDocument(doc) && layerToMove.type === LayerType.video) {
            return false; // Video layers can't be moved above other layers.
        } else if (isMultiImageContentDocument(doc)) {
            const multiImageIndex = this.getMultiImageIndexOfLayer(layerToMove);
            if (multiImageIndex === -1) {
                return false;
            }
            layers = this.getLayersOnMultiImage(multiImageIndex);
        } else {
            layers = doc.layers;
        }

        const layerPosition = layers.indexOf(layerToMove);

        // Layers are ordered in the document from lowest (0) to highest (n - 1)
        // So to move a layer up its position must be lower than the highest layer
        const result = layerPosition < layers.length - 1;

        return result;
    }

    public canMoveLayerDown(layerToMove: Layer): boolean {
        const doc = this.document;
        let layers: Layer[];

        if (isPrintDocument(doc)) {
            const pageIndex = this.getPageIndexOfLayer(layerToMove);
            if (pageIndex === -1) {
                return false;
            }

            layers = this.getLayersOnPage(pageIndex);
        } else if (isMultiImageContentDocument(doc)) {
            const multiImageIndex = this.getMultiImageIndexOfLayer(layerToMove);
            if (multiImageIndex === -1) {
                return false;
            }
            layers = this.getLayersOnMultiImage(multiImageIndex);
        } else {
            layers = doc.layers;
        }

        const layerPosition = layers.indexOf(layerToMove);

        // Layers are ordered in the document from lowest (0) to highest (n - 1)
        // So to move a layer down its position must be higher than 0, unless it's a video template, in which case
        // A video layer must always be the bottom-most layer.
        const lowestAllowedIndex = isVideoDocument(doc) ? 1 : 0;
        const result = layerPosition > lowestAllowedIndex;

        return result;
    }

    public moveLayerUp(layerToMove: Layer): void {
        this.moveLayer(layerToMove, 'up');
    }

    public moveLayerDown(layerToMove: Layer): void {
        this.moveLayer(layerToMove, 'down');
    }

    // eslint-disable-next-line max-statements
    public duplicateLayer(
        layer: Layer,
        options: { toMultiImageIndex?: number; toPageIndex?: number; group?: boolean },
    ): Layer | null {
        const i = this.findLayerIndexById(layer.id);
        if (i === null || i === undefined) {
            return null;
        }

        const doc = this.document;

        let toMultiImageIndex: number | undefined;
        let toPageIndex: number | undefined;

        if (isPrintDocument(doc)) {
            toPageIndex = options.toPageIndex ?? this.pageContext.pageIndex;
        } else if (isMultiImageContentDocument(doc)) {
            toMultiImageIndex = options.toMultiImageIndex ?? this.multiImageContext.imageIndex;
        }

        const newLayer = clone(layer);
        newLayer.id = this.idCount++;

        let originalTitle: string;
        let duplicateNumber = 0;

        const match = (/(.+)\s\((\d+)\)/gi).exec(newLayer.title);
        if (match && match[1]) {
            originalTitle = match[1];
            duplicateNumber = (parseInt(match[1], 10) || 0) + 1;
        } else {
            originalTitle = newLayer.title;
        }

        let layers: Layer[];

        if (isPrintDocument(doc) && toPageIndex) {
            layers = this.getLayersOnPage(toPageIndex);
        } else if (isMultiImageContentDocument(doc) && toMultiImageIndex) {
            layers = this.getLayersOnMultiImage(toMultiImageIndex);
        } else {
            layers = doc.layers;
        }

        let title = originalTitle;
        // eslint-disable-next-line no-loop-func
        while (linq.from(layers).any((l: Layer) => l.title === title)) {
            duplicateNumber++;
            title = `${originalTitle} (${duplicateNumber})`;
        }

        newLayer.title = title;

        doc.layers.splice(i + 1, 0, newLayer);
        this.addDeltaToFirstFrameIfRequired(newLayer);

        if (isPrintDocument(doc)) {
            toPageIndex = options.toPageIndex ?? this.pageContext.pageIndex;
            const page = doc.pages[toPageIndex];
            page.layerIds.push(newLayer.id);
        } else if (isMultiImageContentDocument(doc)) {
            toMultiImageIndex = options.toMultiImageIndex ?? this.multiImageContext.imageIndex;
            const multiImage = doc.multiImage[toMultiImageIndex];
            multiImage.layerIds.push(newLayer.id);
        }

        this.selectLayer(newLayer, options.group);

        return newLayer;
    }

    public toggleLayerVisibility(layer: Layer): void {
        const oldValue = layer.visible === undefined || layer.visible === true;
        const newValue = !oldValue;
        layer.visible = newValue;
        if (this.document.animation !== null && this.document.animation !== undefined) {
            const path = 'visible';
            this.updateAnimationDelta(layer, path, newValue);
        }
        this.captureDocumentChange(`${layer.title} (visibility)`);
    }

    public rectifyPanning(): void {
        if (!this.document.dimensions) {
            return;
        }
        const uiContext = this.uiContext;
        const sourceCanvas = window.document.getElementById(this.canvasId) as HTMLCanvasElement;
        const documentDimensions = this.getPageDimensions();
        if (documentDimensions.width * this.uiContext.zoom <= sourceCanvas.width) {
            uiContext.panHorizontal = 1.0;
        }
        if (documentDimensions.height * this.uiContext.zoom <= sourceCanvas.height) {
            uiContext.panVertical = 1.0;
        }
    }

    public getMouseOverLayer(x: number, y: number): Layer | null {
        if (this.isWithinCanvas({ x, y })) {
            const canvasClick = this.pageToCanvas(x, y);
            const layer = this.getLayerFromPoint(canvasClick.x, canvasClick.y);
            return layer;
        }

        return null;
    }

    public mouseDown(x: number, y: number, event: MouseEvent): void {
        const mouseData = this.uiContext.mouse;
        mouseData.left = true;
        if (this.uiContext.interaction !== null && this.uiContext.interaction !== undefined) {
            this.uiContext.interaction.onMouseDown(this, x, y, event);
        }
        if (this.isWithinCanvas({ x, y })) {
            const sourceCanvas = window.document.getElementById(this.canvasId) as HTMLCanvasElement;
            sourceCanvas.focus();
        }
        this.setLastMousePoint(x, y);
    }

    public mouseMove(x: number, y: number, event: MouseEvent): void {
        const uiContext = this.uiContext;
        const mouseData = uiContext.mouse;
        if (mouseData.left) {
            if (this.uiContext.interaction) {
                this.uiContext.interaction.onMouseMove(this, x, y, event);
            }
            this.setLastMousePoint(x, y);
        }

        this.updateMouseCursor(x, y);
        this.tryHighlightLayer(x, y);
    }

    public mouseUp(x: number, y: number, event: MouseEvent): void {
        const mouseData = this.uiContext.mouse;
        mouseData.left = false;
        if (this.uiContext.interaction) {
            // Hmm...
            this.uiContext.interaction.onMouseUp(this, x, y, event);
        }
        this.setLastMousePoint(null, null);
    }

    public mouseDoubleClick(x: number, y: number, event: MouseEvent): void {
        if (this.uiContext.interaction) {
            this.uiContext.interaction.onMouseDoubleClick(this, x, y, event);
        }
    }

    public updateMouseCursor(x?: number, y?: number): void {
        if (this.uiContext.interaction) {
            const cursor = this.uiContext.interaction.getCursor(this, x, y);
            const $canvas = $(`#${this.canvasId}`);
            $canvas.css('cursor', cursor);
            $canvas.css('cursor', `-webkit-${cursor}`);
        }
    }

    public setMouseAction(action: ActionType): void {
        this.uiContext.action = action;
        const interaction = interactionFactory(this.uiContext.action);
        this.uiContext.interaction = interaction;
    }

    public zoomToFitCanvas(canvasId: string): void {
        const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
        if (canvas) {
            const documentAndBleedSize = this.renderer.getDocumentAndBleedSize(
                this.document,
                this.formats,
                (this.pageContext && this.pageContext.pageIndex) || 0,
            );
            const diff = Math.min(
                canvas.width / documentAndBleedSize.width,
                canvas.height / documentAndBleedSize.height,
            );
            this.setZoom(diff);
        } else {

            console.log(`Couldn't find canvas with ID: ${canvasId}`);
        }
    }

    public zoomToFit(): void {
        this.zoomToFitCanvas(this.canvasId);
    }

    public zoomActualSize(): void {
        this.setZoom(1);
    }

    public zoomIn(): void {
        if (this.uiContext.zoom < ContentBuilder.MAX_ZOOM) {
            this.setZoom(this.roundZoom(this.uiContext.zoom) + this.getZoomIncrement(this.uiContext.zoom + 0.001));
        }
    }

    public zoomOut(): void {
        if (this.uiContext.zoom > ContentBuilder.MIN_ZOOM) {
            this.setZoom(this.roundZoom(this.uiContext.zoom) - this.getZoomIncrement(this.uiContext.zoom - 0.001));
        }
    }

    public createOrRemoveAnimation(): void {
        if (this.document.animation === null || this.document.animation === undefined) {
            this.animationContext = this.createAnimationContext();
            this.document.animation = new BuilderAnimation();
            const frame = this.createFirstAnimationFrame();
            this.document.animation.frames.push(frame);
        } else {
            this.document.animation = null;
        }
    }

    public previousAnimationFrame(): void {
        if (this.animationContext.frameIndex > 0) {
            const newIndex = this.animationContext.frameIndex - 1;
            this.applyDeltasUpToFrame(this.animationContext.frameIndex, newIndex);
            this.animationContext.frameIndex = newIndex;
            this.captureDocumentChange('Previous animation frame');
        }
    }

    public nextAnimationFrame(): void {
        if (this.document.animation && this.animationContext.frameIndex < this.document.animation.frames.length - 1) {
            const newIndex = this.animationContext.frameIndex + 1;
            this.applyDeltasUpToFrame(this.animationContext.frameIndex, newIndex);
            this.animationContext.frameIndex = newIndex;
            this.captureDocumentChange('Next animation frame');
        }
    }

    public setAnimationFrame(newIndex: number): void {
        if (this.document.animation && newIndex >= 0 && newIndex < this.document.animation.frames.length) {
            this.applyDeltasUpToFrame(this.animationContext.frameIndex, newIndex);
            this.animationContext.frameIndex = newIndex;
        }
    }

    public addAnimationFrameBefore(): void {
        const frame = new BuilderAnimationFrame();
        this.addFrame(this.animationContext.frameIndex, frame);
        this.applyDeltasUpToFrame(0, this.animationContext.frameIndex);
        this.captureDocumentChange('Add animation frame');
    }

    public addAnimationFrameAfter(): void {
        const frame = new BuilderAnimationFrame();
        this.addFrame(this.animationContext.frameIndex + 1, frame);
        this.nextAnimationFrame();
        this.captureDocumentChange('Add animation frame');
    }

    public isFirstPage(): boolean {
        const doc = this.document;

        if (!isPrintDocument(doc)) {
            return true;
        }

        return this.pageContext.pageIndex === 0;
    }

    public isLastPage(): boolean {
        const doc = this.document;

        if (isPrintDocument(doc)) {
            if (!doc.pages) {
                return true;
            }

            return this.pageContext.pageIndex === doc.pages.length - 1;
        }

        return true;
    }

    public gotoPreviousPage(): void {
        if (!isPrintDocument(this.document)) {
            return;
        }

        if (this.pageContext.pageIndex > 0) {
            this.pageContext.pageIndex--;
            this.deselectAllLayers();
            this.captureDocumentChange('Previous page');
        }
    }

    public gotoNextPage(): void {
        const doc = this.document;

        if (isPrintDocument(doc)) {
            if (this.pageContext.pageIndex < doc.pages.length - 1) {
                this.pageContext.pageIndex++;
                this.deselectAllLayers();
                this.captureDocumentChange('Previous page');
            }
        }
    }

    public gotoPrintTemplatePage(pageIndex: number): void {
        const doc = this.document;

        if (isPrintDocument(doc)) {
            if (pageIndex >= 0 && pageIndex < doc.pages.length) {
                this.pageContext.pageIndex = pageIndex;
                this.deselectAllLayers();
                this.captureDocumentChange('Jump to page');
            }
        }
    }

    public deletePage(): void {
        const doc = this.document;
        const { pageIndex } = this.pageContext;

        if (isPrintDocument(doc) && doc.pages.length > 1) {
            // Remove all the layers on this page
            const layersToDelete = this.getLayersOnPage(pageIndex);

            for (const layer of layersToDelete) {
                this.deleteLayer(layer);
            }

            doc.pages.splice(pageIndex, 1);

            this.pageContext.pageIndex = Math.min(doc.pages.length - 1, pageIndex);

            this.deselectAllLayers();
            this.captureDocumentChange('Delete page');
        }
    }

    public addPageBefore(): void {
        const doc = this.document;

        if (isPrintDocument(doc)) {
            doc.pages.splice(this.pageContext.pageIndex, 0, {
                layerIds: [],
                orientation: this.getPageOrientation(),
            });
            this.deselectAllLayers();
            this.captureDocumentChange('Add page');
        }
    }

    public addPageAfter(): void {
        const doc = this.document;

        if (isPrintDocument(doc)) {
            doc.pages.splice(this.pageContext.pageIndex + 1, 0, {
                layerIds: [],
                orientation: this.getPageOrientation(),
            });
            this.pageContext.pageIndex++;
            this.deselectAllLayers();
            this.captureDocumentChange('Add page');
        }
    }

    public isFirstMultiImage(): boolean {
        return isMultiImageContentDocument(this.document) ? this.multiImageContext.imageIndex === 0 : true;
    }

    public isLastMultiImage(): boolean {
        return isMultiImageContentDocument(this.document)
            ? this.multiImageContext.imageIndex === this.document.multiImage.length - 1
            : true;
    }

    public gotoMultiImage(multiImageIndex: number): void {
        if (isMultiImageContentDocument(this.document) && multiImageIndex < this.document.multiImage.length) {
            this.multiImageContext.imageIndex = multiImageIndex;
            this.deselectAllLayers();
            this.captureDocumentChange('Go to multi-image index');
        }
    }

    public addMultiImage(): void {
        if (isMultiImageContentDocument(this.document)) {
            const copyOfMultiImage = Array.from(this.document.multiImage);
            copyOfMultiImage.push({ layerIds: [] });
            this.document.multiImage = copyOfMultiImage;
            this.multiImageContext.imageIndex = this.document.multiImage.length - 1;
            this.deselectAllLayers();
            this.captureDocumentChange('Add multi-image');
        }
    }

    public deleteMultiImage(multiImageIndex: number): void {
        const doc = this.document;
        if (isMultiImageContentDocument(doc) && doc.multiImage.length > 1 && multiImageIndex < doc.multiImage.length) {
            const layersToDelete = this.getLayersOnMultiImage(multiImageIndex);
            for (const layer of layersToDelete) {
                this.deleteLayer(layer);
            }
            const copyOfMultiImage = Array.from(doc.multiImage);
            copyOfMultiImage.splice(multiImageIndex, 1);
            this.multiImageContext.imageIndex = Math.min(copyOfMultiImage.length - 1, multiImageIndex);
            this.document.multiImage = copyOfMultiImage;
            this.deselectAllLayers();
            this.captureDocumentChange('Delete multi-image');
        }
    }

    public moveMultiImage(fromIndex: number, toIndex: number): void {
        const doc = this.document;
        if (isMultiImageContentDocument(doc) && doc.multiImage.length > 1) {
            if (
                fromIndex >= 0 &&
                fromIndex < doc.multiImage.length &&
                toIndex >= 0 &&
                toIndex < doc.multiImage.length
            ) {
                const copyOfMultiImage = Array.from(doc.multiImage);
                const [imageToMove] = copyOfMultiImage.splice(fromIndex, 1);
                copyOfMultiImage.splice(toIndex, 0, imageToMove);
                this.document.multiImage = copyOfMultiImage;
                this.multiImageContext.imageIndex = toIndex;
                this.deselectAllLayers();
                this.captureDocumentChange('Reorder multi-image');
            }
        }
    }

    public movePrintTemplatePage(fromIndex: number, toIndex: number): void {
        if (isPrintDocument(this.document) && this.document.pages.length > 1) {
            const doc = this.document;
            if (fromIndex >= 0 && fromIndex < doc.pages.length && toIndex >= 0 && toIndex < doc.pages.length) {
                // Move page
                const copyOfPages = Array.from(doc.pages);
                const [imageToMove] = copyOfPages.splice(fromIndex, 1);
                copyOfPages.splice(toIndex, 0, imageToMove);
                this.document.pages = copyOfPages;
                // Update currently selected page to moved page
                this.pageContext.pageIndex = toIndex;
                this.deselectAllLayers();
                this.captureDocumentChange('Reorder page');
            }
        }
    }

    public toggleMultiImage(): void {
        if (isNullOrUndefined(this.document.multiImage)) {
            this.multiImageContext = this.createMultiImageContext();
            this.document.multiImage = [{ layerIds: [] }];
        } else {
            this.document.multiImage = null;
        }
    }

    public getPageDimensions(): BuilderDocumentDimensions {
        return getPageDimensions(this.document, (this.pageContext && this.pageContext.pageIndex) || 0);
    }

    public getPageOrientation(): BuilderPrintDocumentPageOrientation {
        const doc = this.document;
        const orientation =
            (isPrintDocument(doc) && doc.pages[this.pageContext.pageIndex].orientation) ||
            getPageOrientationFromDimensions(this.document.dimensions);
        return orientation;
    }

    public setPageOrientation(orientation: BuilderPrintDocumentPageOrientation): void {
        const doc = this.document;
        if (isPrintDocument(doc)) {
            doc.pages[this.pageContext.pageIndex].orientation = orientation;
            this.captureDocumentChange('Change page orientation');
        }
    }

    public deleteCurrentAnimationFrame(): void {
        if (this.document.animation && this.document.animation.frames.length > 1) {
            const deletedFrames = this.document.animation.frames.splice(this.animationContext.frameIndex, 1);
            if (this.animationContext.frameIndex === 0) {
                this.copyMissingDeltas(deletedFrames[0], this.document.animation.frames[0]);
            }
            if (this.animationContext.frameIndex >= this.document.animation.frames.length) {
                this.animationContext.frameIndex = this.document.animation.frames.length - 1;
            }
            this.applyDeltasUpToFrame(0, this.animationContext.frameIndex);
            this.captureDocumentChange('Delete animation frame');
        }
    }

    public getCurrentFrame(): BuilderAnimationFrame | undefined {
        if (this.document.animation) {
            return this.document.animation.frames[this.animationContext.frameIndex];
        } else {
            return undefined;
        }
    }

    public startAnimation(): void {
        if (!this.animationContext.running) {
            this.setAnimationFrame(0);
            this.animationContext.running = true;
            this.queueNextFrame();
        }
    }

    public stopAnimation(): void {
        this.animationContext.running = false;
        if (this.animationContext.promise) {
            this.injected.$timeout.cancel(this.animationContext.promise);
            this.animationContext.promise = null;
        }
    }

    public startVideo(): void {
        this.videoPlayer.startFromBeginning();
    }

    public stopVideo(): void {
        this.videoPlayer.pause();
    }

    public toggleMute(): void {
        this.videoPlayer.toggleMute();
    }

    public isVideoDocument(): boolean {
        return isVideoDocument(this.document);
    }

    public isVideoPlaying(): boolean {
        return this.videoPlayer.isPlaying;
    }

    public toggleLooping(): void {
        if (!this.document.animation) {
            return;
        }
        if (this.document.animation.loop !== null && this.document.animation.loop !== undefined) {
            this.document.animation.loop = null;
        } else {
            this.document.animation.loop = 0;
        }
    }

    public toggleLoopForever(): void {
        if (!this.document.animation) {
            return;
        }
        if (this.document.animation.loop !== null && this.document.animation.loop > 0) {
            this.document.animation.loop = 0;
        } else {
            this.document.animation.loop = 1;
        }
    }

    public addChannelData(channelName: ChannelName): void {
        if (!this.document.channelData) {
            this.document.channelData = {};
        }
        const channelDatum = this.injected.channelDataService.createChannelDatum(channelName);
        /*
         * It's a struggle to get this typing to work with TS 3.7 (due to fixes to unsound writes to indexed access
         * types in TS 3.5)
         */
        this.document.channelData[channelName] = channelDatum as any;
    }

    public getChannelDatum<TChannelName extends ChannelName>(
        channelName: TChannelName,
        propertyGetter: (channelData: ChannelDataByName<TChannelName>) => string | null,
    ): string | null {
        return this.injected.channelDataService.getChannelDatum(
            this.textSubstitutionValues,
            this.document,
            channelName,
            propertyGetter,
        );
    }

    public deleteChannelDatum(channelName: ChannelName): void {
        if (this.document.channelData && this.document.channelData[channelName]) {
            delete this.document.channelData[channelName];
        }
    }

    public hasSubstitutionContent(): boolean {
        if (this.document) {
            return this.inspector.hasAnyAutomaticSubstitutionContent(this.document);
        }

        return false;
    }

    public hasTextSubstitutionContent(...placeholders: AutomaticTextPlaceholders[]): boolean {
        if (this.document) {
            return this.inspector.hasTextSubstitutionContent(this.document, placeholders);
        }

        return false;
    }

    public getUsedFonts(): string[] {
        if (this.document) {
            const resources = this.inspector.identifyExternalResources(this.document);
            return resources.fonts;
        } else {
            return [];
        }
    }

    public updateImageSubstitions(
        location: AssignedLocation,
    ): IPromise<{ failedSubstitutions: ImageSubstitutionType[] }> {
        const groupedLayers = linq
            .from(this.document.layers)
            .where(
                x =>
                    (isImageLayer(x) || isMapLayer(x)) &&
                    x.substitutionType !== null &&
                    x.substitutionType !== undefined,
            )
            .groupBy(x => (x as BaseImageLayer).substitutionType);

        const promises = groupedLayers.select(group =>
            this.injected.$q
                .resolve()
                .then<ImageSubstitutionType[] | void>((): IPromise<ImageSubstitutionType[]> | void => {
                    switch (group.key()) {
                        case ImageSubstitutionType.LocationLogo:
                            return this.doLocationLogoSubstitution(location, group.toArray() as ImageLayer[]);
                        case ImageSubstitutionType.LocationMap:
                            return this.doLocationMapSubstitution(location, group.toArray() as ImageLayer[]);
                        default:
                            return undefined;
                    }
                }),
        );

        return this.injected.$q
            .all<ImageSubstitutionType[]>(promises.toArray())
            .then((x: ImageSubstitutionType[][]) => {
                if (x.length === 0) {
                    return { failedSubstitutions: [] };
                }

                const failedSubstitutionTypes = linq
                    .from(x)
                    .aggregate((prev: ImageSubstitutionType[], current) =>
                        linq.from(prev).concat(current).distinct().toArray(),
                    );
                return { failedSubstitutions: failedSubstitutionTypes };
            });
    }

    public updateLayersToSourceImage(url: string, layers: ImageLayer[]): IPromise<void> {
        this.resourceLoadingCount++;
        return this.injected.imageLoaderService
            .loadFileFromExternal(url)
            .then(file => this.injected.imageLoaderService.loadImageFromFile(this.imageCache, this.fileCache, file))
            .then(image => {
                layers.forEach(layer => {
                    this.updateImageLayerSource(layer, image.src);
                });
            })
            .finally(() => this.resourceLoadingCount--);
    }

    public updateImageLayerSource(layer: ImageLayer, location: string | null): void {
        if (layer) {
            layer.locationType = this.getLocationType(location);
            layer.location = location;
            layer.showPlaceholderOverlay = location === null || location === undefined;
        }
    }

    public validateTextFontSizeField(): void {
        if (this.selectedLayerGroup.selectedLayer !== null && isTextLayer(this.selectedLayerGroup.selectedLayer)) {
            const fontSize = this.selectedLayerGroup.selectedLayer.fontSize;
            this.selectedLayerGroup.selectedLayer.fontSize = this.isValidTextFontSize(fontSize) ? fontSize : 1;
        }
    }

    public validateLetterSpacingField(): void {
        if (this.selectedLayerGroup.selectedLayer !== null && isTextLayer(this.selectedLayerGroup.selectedLayer)) {
            const letterSpacing = this.selectedLayerGroup.selectedLayer.letterSpacing;
            const minLetterSpacing = 0;
            const maxLetterSpacing = 20;

            if (
                typeof letterSpacing === 'number' &&
                !isNaN(letterSpacing) &&
                letterSpacing >= minLetterSpacing &&
                letterSpacing <= maxLetterSpacing
            ) {
                this.selectedLayerGroup.selectedLayer.letterSpacing = letterSpacing;
            } else {
                this.selectedLayerGroup.selectedLayer.letterSpacing = minLetterSpacing;
            }
        }
    }

    public shouldRemoveAudioForVideoOutput(): boolean {
        const videoLayers: VideoLayer[] = this.getVideoLayers();
        for (const layer of videoLayers) {
            if (layer.removeAudio === true) {
                return true;
            }
        }
        return false;
    }

    public destroy(): void {
        this.renderer.destroy();
    }

    private deletePageMapForLayer(layerId: number) {
        if (isPrintDocument(this.document)) {
            this.document.pages.forEach(page => {
                page.layerIds = page.layerIds.filter(id => id !== layerId);
            });
        }
    }

    private deleteMultiImageMapForLayer(layerId: number) {
        if (isMultiImageContentDocument(this.document)) {
            this.document.multiImage.forEach(image => {
                image.layerIds = image.layerIds.filter(id => id !== layerId);
            });
        }
    }

    private getHistoryObject(): ContentBuilderHistory {
        return {
            animationFrameIndex: this.animationContext.frameIndex,
            document: this.document,
            linkedAssetLibraryAsset: this.linkedAssetLibraryAsset,
            multiImageContext: this.multiImageContext,
            pageContext: this.pageContext,
            substitutionTextValues: this.textSubstitutionValues,
        };
    }

    private isValidTextFontSize(fontSize: any): fontSize is number {
        return typeof fontSize === 'number' && !isNaN(fontSize) && fontSize >= 1;
    }

    private updateDocumentChange(change: ContentBuilderHistory): void {
        this.animationContext.frameIndex = change.animationFrameIndex;
        this.document = change.document;
        this.linkedAssetLibraryAsset = change.linkedAssetLibraryAsset;
        this.multiImageContext = change.multiImageContext;
        this.pageContext = change.pageContext;
        this.textSubstitutionValues = change.substitutionTextValues;

        this.fixLayerSelection();
    }

    private setupListeners() {
        const sourceCanvas = window.document.getElementById(this.canvasId) as HTMLCanvasElement;
        sourceCanvas.addEventListener('keydown', event => {
            if (this.uiContext.interaction) {
                event.preventDefault();
                this.uiContext.interaction.onKeydown(this, event);
                this.updateMouseCursor();
            }
        });

        sourceCanvas.addEventListener('keyup', event => {
            if (this.uiContext.interaction) {
                event.preventDefault();
                this.uiContext.interaction.onKeyup(this, event);
                this.updateMouseCursor();
            }
        });

        if (this.advancedMode) {
            this.bindBuilderShortcuts();
        }
    }

    private bindBuilderShortcuts() {
        this.builderShortcuts.bind(
            'del backspace',
            () => {
                for (const layer of this.selectedLayerGroup.visibleLayers) {
                    this.deleteLayer(layer);
                }
            },
            'keyup',
        );

        // It does pass back event & combo but the damn types are wrong!
        this.builderShortcuts.bind(
            ['ctrl+z', 'command+z'],
            (event: KeyboardEvent) => {
                event.preventDefault();
                const change = this.documentHistory.undo(this.getHistoryObject());
                if (change) {
                    this.updateDocumentChange(change);
                }
            },
            'keydown',
        );
        this.builderShortcuts.bind(
            ['ctrl+shift+z', 'command+shift+z', 'ctrl+y', 'command+y'],
            (event: KeyboardEvent) => {
                event.preventDefault();
                const change = this.documentHistory.redo(this.getHistoryObject());
                if (change) {
                    this.updateDocumentChange(change);
                }
            },
            'keydown',
        );

        this.builderShortcuts.bind(
            ['up', 'down', 'left', 'right'],
            (event: KeyboardEvent, combo: string) => {
                event.preventDefault();
                this.moveLayerGroupByArrowKeys(combo, false);
            },
            'keydown',
        );

        this.builderShortcuts.bind(
            ['shift+up', 'shift+down', 'shift+left', 'shift+right'],
            (event: KeyboardEvent, combo: string) => {
                event.preventDefault();
                this.moveLayerGroupByArrowKeys(combo.split('+')[1], true);
            },
            'keydown',
        );

        this.builderShortcuts.bind(
            [
                'ctrl+shift+up',
                'ctrl+shift+down',
                'ctrl+shift+left',
                'ctrl+shift+right',
                'command+shift+up',
                'command+shift+down',
                'command+shift+left',
                'command+shift+right',
            ],
            (event: KeyboardEvent, combo: string) => {
                event.preventDefault();
                this.alignLayersByArrowKeys(combo.split('+')[2]);
            },
            'keydown',
        );

        this.builderShortcuts.bind(
            ['ctrl+shift+c', 'command+shift+c', 'ctrl+shift+h', 'command+shift+h'],
            (event: KeyboardEvent, combo: string) => {
                event.preventDefault();
                this.centerAlignLayerGroup(combo.split('+')[2] === 'c');
            },
            'keydown',
        );
    }

    private alignLayersByArrowKeys(arrowKey: string) {
        const isSingle = this.selectedLayerGroup.isAny(x => x.visible) && !this.selectedLayerGroup.isMultiple();
        let containingRect: IRectangle | null = {
            height: this.renderer.workCanvas.height,
            width: this.renderer.workCanvas.width,
            x: 0,
            y: 0,
        };
        let description = '';
        if (!isSingle) {
            containingRect = this.selectedLayerGroup.getContainingRectangle(false);
        }
        if (containingRect) {
            for (const layer of this.selectedLayerGroup.visibleLayers.filter(x => !isVideoLayer(x))) {
                const containerSize = getRectangleContainingForLayerCorners(layer);
                if (containerSize) {
                    switch (arrowKey) {
                        case 'up':
                            layer.position.y = containingRect.y - containingRect.height / 2 + containerSize.height / 2;
                            description = 'Top Align';
                            break;
                        case 'down':
                            layer.position.y = containingRect.y + containingRect.height / 2 - containerSize.height / 2;
                            description = 'Bottom Align';
                            break;
                        case 'left':
                            layer.position.x = containingRect.x - containingRect.width / 2 + containerSize.width / 2;
                            description = 'Left Align';
                            break;
                        case 'right':
                            layer.position.x = containingRect.x + containingRect.width / 2 - containerSize.width / 2;
                            description = 'Right Align';
                            break;
                        default:
                            break;
                    }
                }
            }

            this.captureDocumentChange(description);
        }
    }

    private centerAlignLayerGroup(vertical = true) {
        if (!this.selectedLayerGroup.isAny() || !this.selectedLayerGroup.visibleLayersExists()) {
            return;
        }

        const centerLayer = this.selectedLayerGroup.visibleLayers.reduce((prev, current) => {
            if (prev) {
                if (vertical) {
                    return prev.width < current.width ? current : prev;
                } else {
                    return prev.height < current.height ? current : prev;
                }
            }
            return current;
        });

        const isSingle = !this.selectedLayerGroup.isMultiple(true);

        for (const layer of this.selectedLayerGroup.visibleLayers.filter(x => !isVideoLayer(x))) {
            if (vertical) {
                layer.position.x = !isSingle ? centerLayer.position.x : 0;
            } else {
                layer.position.y = !isSingle ? centerLayer.position.y : 0;
            }
        }

        this.captureDocumentChange(`Center Align (${vertical ? 'Vertical' : 'Horizontal'})`);
    }

    private moveLayerGroupByArrowKeys(key: string, applyModifier = false) {
        const movementAmount = applyModifier ? 10 : 1;
        const movement = { x: 0, y: 0 };
        switch (key) {
            case 'up':
                movement.y -= movementAmount;
                break;
            case 'down':
                movement.y += movementAmount;
                break;
            case 'left':
                movement.x -= movementAmount;
                break;
            case 'right':
                movement.x += movementAmount;
                break;
            default:
                break;
        }

        for (const layer of this.selectedLayerGroup.visibleLayers.filter(x => !isVideoLayer(x))) {
            layer.position.x += movement.x;
            layer.position.y += movement.y;
        }

        this.captureDocumentChange('Move Layers');
    }

    private fixLayerSelection() {
        if (this.selectedLayerGroup.isAny()) {
            for (const layer of this.selectedLayerGroup.layers) {
                if (
                    !this.findLayerById(layer.id) ||
                    !this.isLayerOnPage(layer) ||
                    !this.isLayerOnMultiImageOption(layer)
                ) {
                    this.selectedLayerGroup.remove(layer);
                }
            }

            if (!this.selectedLayerGroup.isAny()) {
                this.selectDocument();
            }
        }
    }

    private isWithinCanvas(point: IPoint): boolean {
        const pageData = this.calculateCanvasPageData();
        return isPointWithin(
            point,
            {
                height: pageData.height,
                width: pageData.width,
                x: pageData.centreX,
                y: pageData.centreY,
            },
            0,
        );
    }

    private async populateImageCache(document: BuilderDocument) {
        this.imageCache.clear();
        const resources = this.inspector.identifyExternalResources(document);
        const promises: Array<IPromise<void>> = [];
        for (const imageUrl of resources.images) {
            this.resourceLoadingCount++;
            const promise: IPromise<void> = this.injected.imageLoaderService
                .loadImageFromExternal(this.imageCache, imageUrl)
                .then(() => {
                    this.resourceLoadingCount--;
                });
            promises.push(promise);
        }
        for (const videoUrl of resources.videos) {
            this.resourceLoadingCount++;
            const promise: IPromise<void> = this.injected.videoLoaderService
                .loadVideoFromUrl(this.videoCache, videoUrl)
                .then(() => {
                    this.resourceLoadingCount--;
                });
            promises.push(promise);
        }

        return this.injected.$q
            .all(promises)
            .then(() => {
                this.resourceLoadingCount = 0; // Dodgy
            })
            .catch(err => {
                /*
                 * TODO: have an "errored" state in the builder, that prevents users from publishing.
                 * Do not reset the resource loading count, since it would allow users to publish documents with
                 * missing images.
                 */
                throw err;
            });
    }

    private initEditorFromDocument(document: BuilderDocument) {
        this.simpleEditor.clear();
        for (const editableField of document.editableFields) {
            // DS-6442: Handle editable template background color field (and other template fields in the future)
            if (!editableField.layerId) {
                this.initDocumentEditableField(document, editableField);
            } else {
                const layer = this.findLayerById(editableField.layerId);
                if (layer) {
                    this.initEditorField(layer, editableField);
                } else {
                    const { label, layerId } = editableField;

                    console.log(`Can't find a layer with ID '${layerId}' for editable field '${label}'`);
                }
            }
        }
    }

    private initIdCount(document: BuilderDocument) {
        let maxId = 1;
        for (const layer of document.layers) {
            if (maxId < layer.id) {
                maxId = layer.id;
            }
        }
        this.idCount = maxId + 1;
    }

    private generateLayerTitle(): string {
        return `New Layer #${this.document.layers.length + 1}`;
    }

    private addLayerByType(layerType: LayerType): Layer {
        let layer: Layer;
        if (layerType === LayerType.text) {
            layer = new TextLayer(this.idCount++);
        } else {
            layer = new Layer(this.idCount++, layerType);
        }
        layer.title = this.generateLayerTitle();

        if (this.document.format === BuilderDocumentFormat.print) {
            layer.fill = colorSchemeHelpers.cmyk.toString(convertToScheme(layer.fill, 'cmyk')!);
            layer.stroke = colorSchemeHelpers.cmyk.toString(convertToScheme(layer.stroke, 'cmyk')!);
        }

        return this.addLayer(layer);
    }

    private getLocationType(location: string | null) {
        if (location) {
            return location.lastIndexOf('blob:', 0) === 0 ? LocationType.local : LocationType.external;
        } else {
            return LocationType.local;
        }
    }

    private scaleToFill(input: ISize, output: ISize): ISize {
        let scale = output.width / input.width;
        if (input.height * scale < output.height) {
            scale = output.height / input.height;
        }

        const scaled = { height: round(input.height * scale), width: round(input.width * scale) };

        return scaled;
    }

    private calculateCanvasPageData() {
        const sourceCanvas = document.getElementById(this.canvasId)!;
        const rect = sourceCanvas.getBoundingClientRect();
        // Rect is relative to the viewport, so calculate page rect from the document's rect
        const bodyRect = document.body.getBoundingClientRect();
        const top = rect.top - bodyRect.top;
        const left = rect.left - bodyRect.left;
        const halfWidth = rect.width / 2;
        const halfHeight = rect.height / 2;
        const centreX = left + halfWidth;
        const centreY = top + halfHeight;
        return {
            centreX,
            centreY,
            halfHeight,
            halfWidth,
            height: rect.height,
            left,
            top,
            width: rect.width,
        };
    }

    private findLayerById(layerId: number) {
        for (const layer of this.document.layers) {
            if (layer.id === layerId) {
                return layer;
            }
        }
        return null;
    }

    private findLayerIndexById(layerId: number) {
        for (let i = 0; i < this.document.layers.length; i++) {
            const layer = this.document.layers[i];
            if (layer.id === layerId) {
                return i;
            }
        }

        console.log(`No layer found with ID: ${layerId}`);
        return null;
    }

    private getFieldValue(layer: Layer, path: string): Untyped {
        return this.injected.builderCommonService.getFieldValue<Layer>(layer, path);
    }

    private setFieldValue(layer: Layer, path: string, value: unknown) {
        return this.injected.builderCommonService.setFieldValue(layer, path, value);
    }

    private initEditorField(layer: Layer, editableField: EditableField) {
        const currentValue = this.getFieldValue(layer, editableField.path);
        this.simpleEditor.addField(layer, editableField, currentValue);
    }

    private initDocumentEditableField(document: BuilderDocument, editableField: EditableField): void {
        const currentValue = this.document[editableField.path];
        this.simpleEditor.addField(document, editableField, currentValue);
    }

    private addEditableField(
        layerOrDocument: BuilderDocument | Layer,
        fieldPath: string,
        controlType: string,
        label: string,
    ) {
        if (isBuilderDocument(layerOrDocument)) {
            const editableField: EditableField = {
                control: controlType,
                label,
                path: fieldPath,
            };
            this.document.editableFields.push(editableField);
            this.initDocumentEditableField(layerOrDocument, editableField);
        } else {
            const editableField: EditableField = {
                control: controlType,
                label,
                layerId: layerOrDocument.id,
                path: fieldPath,
            };
            this.document.editableFields.push(editableField); // Save the configuration
            this.initEditorField(layerOrDocument, editableField); // Init the UI
        }
    }

    private deleteEditableField(layerOrDocument: BuilderDocument | Layer, fieldPath: string, control: string) {
        if (isBuilderDocument(layerOrDocument)) {
            const result = this.simpleEditor.deleteTemplateField(fieldPath, control);
            if (result !== null) {
                this.document.editableFields.splice(result.index, 1);
            }
        } else {
            const result = this.simpleEditor.deleteField(layerOrDocument.id, fieldPath, control);
            if (result) {
                this.document.editableFields.splice(result.index, 1);
            }
        }
    }

    private deleteEditableFieldsForLayer(layerId: number) {
        for (let i = this.document.editableFields.length - 1; i >= 0; i--) {
            const editableField = this.document.editableFields[i];
            if (editableField.layerId === layerId) {
                this.document.editableFields.splice(i, 1);
            }
        }
    }

    private moveLayer(layer: Layer, direction: 'down' | 'up'): void {
        const canMoveLayer = direction === 'up' ? this.canMoveLayerUp.bind(this) : this.canMoveLayerDown.bind(this);

        if (!canMoveLayer(layer)) {
            throw new CannotMoveLayer(direction);
        }

        const doc = this.document;
        let layers: Layer[];

        if (isPrintDocument(doc)) {
            const pageIndex = this.getPageIndexOfLayer(layer);
            layers = this.getLayersOnPage(pageIndex);
        } else if (isMultiImageContentDocument(doc)) {
            const multiImageIndex = this.getMultiImageIndexOfLayer(layer);
            layers = this.getLayersOnMultiImage(multiImageIndex);
        } else {
            layers = doc.layers;
        }

        const index = layers.indexOf(layer);
        const offset = direction === 'up' ? 1 : -1;
        const layerToSwapWith = layers[index + offset];

        const absoluteIndex = doc.layers.indexOf(layer);
        const absoluteIndexToSwapWith = doc.layers.indexOf(layerToSwapWith);

        this.document.layers[absoluteIndexToSwapWith] = layer;
        this.document.layers[absoluteIndex] = layerToSwapWith;
    }

    private setLastMousePoint(x: number | null, y: number | null) {
        const mouseData = this.uiContext.mouse;
        mouseData.lastX = x;
        mouseData.lastY = y;
    }

    private tryHighlightLayer(x: number, y: number) {
        const layer = this.getMouseOverLayer(x, y);
        this.highlightLayer = layer;
    }

    private getZoomIncrement(zoom: number) {
        let zoomIncrement: number;
        if (zoom < 0.1) {
            zoomIncrement = 0.01;
        } else if (zoom < 1) {
            zoomIncrement = 0.1;
        } else if (zoom < 2) {
            zoomIncrement = 0.25;
        } else if (zoom < 4) {
            zoomIncrement = 0.5;
        } else if (zoom < 8) {
            zoomIncrement = 1;
        } else {
            zoomIncrement = 2;
        }
        return zoomIncrement;
    }

    private setZoom(value: number) {
        this.uiContext.zoom = clamp(ContentBuilder.MIN_ZOOM, ContentBuilder.MAX_ZOOM, value);
    }

    private roundZoom(zoom: number): number {
        return Math.round(zoom * 100) / 100;
    }

    private createAnimationDelta(layer: Layer) {
        const delta = new BuilderAnimationDelta(
            layer.id,
            'visible',
            layer.visible === undefined || layer.visible === true,
        );
        return delta;
    }

    private createFirstAnimationFrame() {
        const frame = new BuilderAnimationFrame();
        for (const layer of this.document.layers) {
            const delta = this.createAnimationDelta(layer);
            frame.deltas.push(delta);
        }
        return frame;
    }

    private addDeltaToFirstFrameIfRequired(layer: Layer) {
        if (this.document.animation) {
            const frame = this.document.animation.frames[0];
            const delta = this.createAnimationDelta(layer);
            frame.deltas.push(delta);
        }
    }

    private applyDeltasUpToFrame(startIndex: number, endIndex: number) {
        if (this.document.animation) {

            startIndex += 1;
            if (endIndex < startIndex) {
                // Rewind to start, apply from there.

                startIndex = 0;
            }
            for (let i = startIndex; i <= endIndex && i < this.document.animation.frames.length; i++) {
                const frame = this.document.animation.frames[i];
                for (const delta of frame.deltas) {
                    const layer = this.findLayerById(delta.layerId);
                    if (layer) {
                        this.setFieldValue(layer, delta.path, delta.newValue);
                    } else {

                        console.log(`Could not apply delta for missing layer: ${delta.layerId}`);
                    }
                }
            }
        }
    }

    private addFrame(index: number, frame: BuilderAnimationFrame) {
        if (this.document.animation) {
            this.document.animation.frames.splice(index, 0, frame);
        }
    }

    private addDelta(frame: BuilderAnimationFrame, delta: BuilderAnimationDelta) {
        frame.deltas.push(delta);
    }

    private findDeltaByLayerIdAndPath(layerId: number, path: string, deltas: BuilderAnimationDelta[]) {
        for (const delta of deltas) {
            if (delta.layerId === layerId && delta.path === path) {
                return delta;
            }
        }
        return null;
    }

    private copyMissingDeltas(srcFrame: BuilderAnimationFrame, dstFrame: BuilderAnimationFrame) {
        const newDeltas = [];
        for (const srcDelta of srcFrame.deltas) {
            const dstDelta = this.findDeltaByLayerIdAndPath(srcDelta.layerId, srcDelta.path, dstFrame.deltas);
            if (dstDelta) {
                newDeltas.push(dstDelta);
            } else {
                newDeltas.push(srcDelta);
            }
        }
        dstFrame.deltas = newDeltas;
    }

    private deleteAnimationDeltasForLayer(layerId: number) {
        if (this.document.animation) {
            for (const frame of this.document.animation.frames) {
                for (let i = frame.deltas.length - 1; i >= 0; i--) {
                    const delta = frame.deltas[i];
                    if (delta.layerId === layerId) {
                        frame.deltas.splice(i, 1);
                    }
                }
            }
        }
    }

    private getExistingDeltaByLayerIdAndPath(frame: BuilderAnimationFrame, layerId: number, path: string) {
        for (const delta of frame.deltas) {
            if (delta.layerId === layerId && delta.path === path) {
                return delta;
            }
        }
        return null;
    }

    private updateAnimationDelta(layer: Layer, path: string, newValue: any) {
        const frame = this.getCurrentFrame();
        if (frame) {
            let delta = this.getExistingDeltaByLayerIdAndPath(frame, layer.id, path);
            if (delta) {
                delta.newValue = newValue;
            } else {
                delta = this.createAnimationDelta(layer);
                this.addDelta(frame, delta);
            }
        }
    }

    private queueNextFrame() {
        const frame = this.getCurrentFrame();
        if (frame) {
            const duration = frame.duration || 100;
            const promise: ng.IPromise<void> = this.injected.$timeout(this.advanceAnimation.bind(this), duration);
            this.animationContext.promise = promise;
        }
    }

    private getAnimationLength() {
        if (!this.document.animation) {
            return 0;
        }
        return this.document.animation.frames.length;
    }

    private applyNextFrame(): boolean {
        if (this.document.animation) {
            let newIndex = this.animationContext.frameIndex + 1;
            const numFrames = this.getAnimationLength();
            if (newIndex >= numFrames) {
                const loop = this.document.animation.loop;
                if (loop !== null && this.animationContext.loopCount < loop) {
                    // Can continue looping.
                    this.animationContext.loopCount++;
                } else if (loop !== 0) {
                    // No loop, or reached loop count. stop.
                    return false;
                }
                newIndex = 0;
            }
            this.applyDeltasUpToFrame(this.animationContext.frameIndex, newIndex);
            this.animationContext.frameIndex = newIndex;
            return true;
        } else {
            return false;
        }
    }

    private advanceAnimation() {
        if (!this.document.animation) {
            return;
        }
        const shouldContinue = this.applyNextFrame();
        if (shouldContinue) {
            this.queueNextFrame();
        } else {
            this.stopAnimation();
        }
    }

    private createAnimationContext(): AnimationContext {
        return {
            frameIndex: 0,
            loopCount: 0,
            promise: null,
            running: false,
        };
    }

    private createMultiImageContext(): ContentBuilderMultiImageContext {
        return { imageIndex: 0 };
    }

    private doLocationLogoSubstitution(
        location: AssignedLocation,
        layers: ImageLayer[],
    ): IPromise<ImageSubstitutionType[]> {
        if (location.logo !== null && location.logo !== undefined && !!location.logo.url) {
            return this.updateLayersToSourceImage(location.logo.url, layers).then(() => []);
        } else {
            layers.forEach(x => this.updateImageLayerSource(x, null));
            return this.injected.$q.resolve([ImageSubstitutionType.LocationLogo]);
        }
    }

    private doLocationMapSubstitution(
        location: AssignedLocation,
        layers: ImageLayer[],
    ): IPromise<ImageSubstitutionType[]> {
        if (location.map && location.map.upload && location.map.upload.url) {
            return this.updateLayersToSourceImage(location.map.upload.url, layers).then(() => []);
        } else {
            layers.forEach(x => this.updateImageLayerSource(x, null));
            return this.injected.$q.resolve([ImageSubstitutionType.LocationMap]);
        }
    }

    /**
     * Check the letterSpacing property in text layers of the builder document and initialise to 0 if undefined.
     */
    private checkLetterSpacingField(): void {
        for (const layer of this.document.layers) {
            if (isTextLayer(layer) && !layer.letterSpacing) {
                layer.letterSpacing = 0;
            }
        }
    }

    private checkEditorFields(): void {
        this.checkLetterSpacingField();
    }
}

// The CB factory is an angular service, since the factory itself is a singleton that needs to be constructed.
angular.module('app').service(ContentBuilderFactory.SID, ContentBuilderFactory);
