/* eslint-disable jsdoc/no-undefined-types */

/// <reference path="../../../typings/browser.d.ts" />
import { CurrencyDefinitions, LocationId } from '@deltasierra/shared';
import { Constructor } from '@deltasierra/type-utilities';
import { AgencyNotificationsService } from '../agency/notifications/agencyNotificationsService';
import { LoginFlowService } from '../account/loginFlowService';
import { MvAuth } from '../account/mvAuth';
import { MvIdentity } from '../account/mvIdentity';
import { AgencyApiClient } from '../agencies/agencyApiClient';
import { AgencyUserApiClient } from '../agencies/agencyUserApiClient';
import { MvClient } from '../clients/mvClient';
import { BuilderConstants } from '../contentBuilder/builderConstants';
import { EmailPublishService } from '../contentBuilder/email/publish/emailPublishService';
import { ImageLoaderService } from '../contentBuilder/imageLoaderService';
import ImagePublishService from '../contentBuilder/publish/ImagePublishService';
import { VideoPublishService } from '../contentBuilder/publish/VideoPublishService';
import { I18nService } from '../i18n';
import { FacebookPublishService } from '../integration/publish/facebookPublishService';
import { InstagramPublishService } from '../integration/publish/instagramPublishService';
import { StripeApiClient } from '../integration/stripe/stripeApiClient';
import { LocationUserApiClient } from '../locations/locationUserApiClient';
import { MvLocation } from '../locations/mvLocation';
import { CurrencyService } from '../payments/currencyService';
import { PlannerDateService } from '../planner/plannerDateService';
import { PlannerUIService } from '../planner/plannerUIService';
import { SocketService } from '../sockets/socketService';
import { ModalInstance, ModalService, ModalServiceOptions } from '../typings/angularUIBootstrap/modalService';
import { MvNavbar } from '../account/mvNavbar';
import { DataUtils } from './dataUtils';
import { FileUtils } from './fileUtils';
import { MvNotifier } from './mvNotifier';
import { SentryService } from './sentryService';
import { UploadService } from './uploadService';
import IPromise = angular.IPromise;
import IHttpService = angular.IHttpService;
import IScope = angular.IScope;
import ICompileService = angular.ICompileService;
import IRootScopeService = angular.IRootScopeService;
import ILocationService = angular.ILocationService;
import ITimeoutService = angular.ITimeoutService;

export type BindingType = '@' | '&' | '<' | '=';
export type OptionalBindingType = '@?' | '&?' | '<?' | '=?';
export const OneWayBinding: BindingType = '<';
export const OptionalOneWayBinding: OptionalBindingType = '<?';
export const TwoWayBinding: BindingType = '=';
export const OptionalTwoWayBinding: OptionalBindingType = '=?';
export const ExpressionBinding: BindingType = '&';
export const OptionalExpressionBinding: OptionalBindingType = '&?';
export const StringBinding: BindingType = '@';
export const OptionalStringBinding: OptionalBindingType = '@?';

export type DirectiveRestriction = 'A' | 'E' | 'EA'; // We don't allow other restrictions like class or comment
export const RestrictToAttribute: DirectiveRestriction = 'A';
export const RestrictToElement: DirectiveRestriction = 'E';
export const RestrictToElementOrAttribute: DirectiveRestriction = 'EA';

export type ControllerBindings<TController> = {
    [K in keyof TController]?: BindingType;
};

export type ChangesObject<TController> = {
    [K in keyof TController]?: {
        currentValue: TController[K];
        previousValue: TController[K];
        isFirstChange(): boolean;
    };
};

export interface ILifecycleHooks {
    $onInit?(): void;
    $onChanges?(changesObj: ChangesObject<this>): void;
    $doCheck?(): void;
    $onDestroy?(): void;
    $postLink?(): void;
}

export const $compileSID = '$compile';
export const $documentSID = '$document';
export const $elementSID = '$element';
export const $filterSID = '$filter';
export const $httpSID = '$http';
export const $interpolateSID = '$interpolate';
export const $intervalSID = '$interval';
export const $kookiesSID = '$kookies';
export const $locationSID = '$location';
export const $logSID = '$log';
export const $parseSID = '$parse';
export const $qSID = '$q';
export const $resourceSID = '$resource';
export const $rootScopeSID = '$rootScope';
export const $routeSID = '$route';
export const $routeParamsSID = '$routeParams';
export const $sanitizeSID = '$sanitize';
export const $scopeSID = '$scope';
export const $templateCacheSID = '$templateCache';
export const $timeoutSID = '$timeout';
export const $windowSID = '$window';

export const EVENT_DESTROY = '$destroy';

export interface IRoute {
    current: {
        activeTab?: string;
    };
    reload(): void;
}

export type ExpressionCallback<TLocals, R = any> = (locals: TLocals) => R;

export interface InjecteeClassConstructor<TControllerClass> extends Constructor<TControllerClass> {
    $inject: string[];
}

/**
 * Creates a directive using basic component functionality.
 * (I had issues using AngularJS's "angular.module().component()", but if we can get it working we don't need this
 * helper function; however, this function has good type safety between bindings and the class, so we might want to
 * keep it.)
 *
 * @param controller - The component's controller
 * @param templateUrl - The URL of the template
 * @param [bindings] - A mapping of properties and their binding type
 * @returns The component config
 */
export function simpleComponent<TControllerClass>(
    controller: InjecteeClassConstructor<TControllerClass>,
    templateUrl: string,
    bindings?: { [P in keyof TControllerClass]?: BindingType | OptionalBindingType },
): ng.IDirective<ng.IScope> {
    return {
        bindToController: true,
        controller,
        controllerAs: 'ctrl',
        restrict: 'E',
        scope: bindings,
        templateUrl,
    };
}

export function actualComponent<TControllerClass>(
    controller: ILifecycleHooks & InjecteeClassConstructor<TControllerClass>,
    templateUrl: string,
    bindings?: { [P in keyof TControllerClass]?: BindingType | OptionalBindingType },
): ng.IComponentOptions {
    return {
        bindings,
        controller,
        controllerAs: 'ctrl',
        templateUrl,
    };
}

type ModalLocals<TControllerClass> = { [P in keyof TControllerClass]?: TControllerClass[P] };
type ModalResolve<TLocals> = { [P in keyof TLocals]?: () => TLocals[P] };
export function simpleModal<TControllerClass>(
    $modal: ModalService,
    controller: Constructor<TControllerClass>,
    templateUrl: string,
    locals?: ModalLocals<TControllerClass>,
    modalOptions: Partial<ModalServiceOptions<any>> = {},
): ModalInstance {
    let resolve: ModalResolve<typeof locals> | undefined;
    if (locals) {
        resolve = {};
        for (const key in locals) {
            if (Object.prototype.hasOwnProperty.call(locals, key)) {
                resolve[key] = () => locals[key];
            }
        }
    }
    return $modal.open({
        controller,
        controllerAs: 'ctrl',
        resolve,
        templateUrl,
        ...modalOptions,
    } as any); // There is a conflict in the definition between `modalOptions` and `ModalServiceOptions`
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISanitize { }

export interface IRouteParams {
    [key: string]: string;
}

export interface RouteAuthResolver {
    auth: ['mvAuth', (auth: MvAuth) => IPromise<boolean>];
}

interface IRouteProviderBase {
    resolve?: RouteAuthResolver;
}

interface IRouteProviderRedirectOptions extends IRouteProviderBase {
    redirectTo: string | (() => string);
}

interface IRouteProviderPageOptions extends IRouteProviderBase {
    templateUrl: string;

    controller?: Function | string;
    controllerAs?: string;
    activeTab?: string;
    reloadOnSearch?: boolean;
}

type IRouteProviderOptions = IRouteProviderPageOptions | IRouteProviderRedirectOptions;

export interface IRouteProvider {
    when(route: string, options: IRouteProviderOptions): IRouteProvider;
    otherwise(options: IRouteProviderRedirectOptions): IRouteProvider;
}

export interface TableDisplayCustomizationCookieData {
    columns: {
        [key: string]: boolean;
    };
    filters: {
        [key: string]: boolean;
    };
}

type OverviewFilterCookies =
    | 'overviewSearchclient'
    | 'overviewSearchdate'
    | 'overviewSearchitem'
    | 'overviewSearchlocation'
    | 'overviewSearchstatus';

export type TableDisplayCustomizationCookies = 'overviewDisplayCustomization';

type KnownCookies =
    | OverviewFilterCookies
    | TableDisplayCustomizationCookies
    | 'countryCode'
    | 'dismissedMaintenanceNotificationId'
    | 'isBreakpointLabelVisible'
    | 'leftNavigationPreviouslyEnabled'
    | 'plannerLocation'
    | 'plannerMonth'
    | 'plannerView'
    | 'plannerWeek'
    | 'tryThisMenuSubSection';

export interface IKookies {
    get(cookie: 'countryCode', type: StringConstructor): string | undefined;

    get(
        cookie: 'isBreakpointLabelVisible',
        options: {
            path: string;
        },
    ): boolean | undefined;

    get(cookie: 'plannerLocation', type: NumberConstructor): LocationId | undefined;

    get(cookie: 'plannerView'): 'month' | 'week' | undefined;

    get(cookie: TableDisplayCustomizationCookies): TableDisplayCustomizationCookieData | undefined;
    get(cookie: 'tryThisMenuSubSection', type: NumberConstructor): number | null | undefined;
    get(cookie: 'leftNavigationPreviouslyEnabled', type: NumberConstructor): 0 | 1 | undefined;

    get(
        cookie: KnownCookies,
        options?: {
            path?: string;
        },
    ): string | undefined;

    get(cookie: KnownCookies, type: NumberConstructor): number | undefined;
    set(
        cookie: TableDisplayCustomizationCookies,
        value: TableDisplayCustomizationCookieData,
        options: {
            domain?: string;
            path: string;
            secure: boolean;
        },
    ): void;
    set(
        cookie: KnownCookies,
        value: boolean | number | string | null,
        options?: {
            domain?: string;
            expires?: Date | number;
            path?: string;
            secure?: boolean;
        },
    ): void;
}

/**
 * This needs to be manually updated with any newly added services.
 */
export type InjectableServices = {
    $compile: ICompileService;
    $http: IHttpService;
    $location: ILocationService;
    $modal: ModalService;
    $rootScope: IRootScopeService;
    $route: IRoute;
    $routeParams: IRouteParams;
    $scope: IScope;
    $kookies: IKookies;
    $timeout: ITimeoutService;
    AgencyApiClient: AgencyApiClient;
    AgencyNotificationsService: AgencyNotificationsService;
    AgencyUserApiClient: AgencyUserApiClient;
    builderConstants: BuilderConstants;
    currencyDefinitions: CurrencyDefinitions;
    currencyService: CurrencyService;
    dataUtils: DataUtils;
    EmailPublishService: EmailPublishService;
    facebookPublishService: FacebookPublishService;
    instagramPublishService: InstagramPublishService;
    fileUtils: FileUtils;
    I18nService: I18nService;
    ImagePublishService: ImagePublishService;
    imageLoaderService: ImageLoaderService;
    LocationUserApiClient: LocationUserApiClient;
    LoginFlowService: LoginFlowService;
    mvAuth: MvAuth;
    mvClient: MvClient;
    mvIdentity: MvIdentity;
    mvLocation: MvLocation;
    mvNavbar: MvNavbar;
    /**
     * @deprecated
     */
    mvNotifier: MvNotifier;
    plannerDateService: PlannerDateService;
    PlannerUIService: PlannerUIService;
    SentryService: SentryService;
    SocketService: SocketService;
    StripeApiClient: StripeApiClient;
    uploadService: UploadService;
    VideoPublishService: VideoPublishService;
};

/**
 * Get an AngularJS service by its ID. Double check that the SID and matching service exist in the
 * AngularInjectedServices type definition - you may need to add any missing services.
 *
 * Example useage:
 *  ```js
 *      getService(MvNotifier.SID)
 *  ```
 *
 * @see {@link AngularInjectedServices} for a list of supported services. You may need to add any missing services.
 * @param serviceId - The SID of the service to retrieve
 * @returns The service
 * @throws Will throw an error if there is no matching service for the SID
 */
export function getService<TServiceId extends keyof InjectableServices>(
    serviceId: TServiceId,
): InjectableServices[TServiceId] {
    const service = angular.element(document.body).injector().get(serviceId);
    if (!service) {
        throw new Error(`Unexpected SID: ${serviceId}`);
    }
    return service;
}
